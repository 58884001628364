import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Table } from "antd";

import cubejs from "../cube";

import Loader from "../components/Loader";

import {
  LineChart,
  HorizontalBarChart as HorizonBarChart,
} from "../components/visualizations";
import { generateBarData, generateLineData } from "../utils/visualizations";

import { formatDate } from "../utils/helpers";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import { CHART_MAIN_COLOR } from "../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });

export default class CommodityExchange extends Component {
  state = {
    filtersData: {
      "CommodityExchange.exchangeName": {
        key: "CommodityExchange.exchangeName",
        title: "Товарные биржи",
        options: [],
        values: [],
      },
    },
    range: [moment().subtract(7, "d"), moment()],
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "CommodityExchange.date",
        operator: "inDateRange",
        values: range.map((item) => moment(item).format("YYYY-MM-DD")),
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Данные по товарным биржам на период c{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <ChartCard title="Средняя цена в долларах США">
            <QueryRenderer
              query={{
                measures: ["CommodityExchange.usdAvg"],
                dimensions: ["CommodityExchange.exchangeName"],
                filters,
                order: {
                  "CommodityExchange.usdAvg": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="chart1"
                    {...generateBarData(resultSet)}
                    colorsArr={[CHART_MAIN_COLOR]}
                    wrapLabels
                    showValues
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Динамика цен в долларах США">
            <QueryRenderer
              query={{
                measures: ["CommodityExchange.usdNumber"],
                dimensions: [
                  "CommodityExchange.exchangeName",
                  "CommodityExchange.date.day",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="LineChart"
                    {...generateLineData(resultSet)}
                    dateAxis
                    dotted
                    rotate
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title="Таблица">
            <QueryRenderer
              query={{
                measures: [
                  "CommodityExchange.usdAvg",
                  "CommodityExchange.euroAvg",
                  "CommodityExchange.poundAvg",
                ],
                dimensions: [
                  "CommodityExchange.id",
                  "CommodityExchange.exchangeName",
                  "CommodityExchange.dataSource",
                  "CommodityExchange.date.day",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <Table
                    loading={{
                      spinning: !resultSet,
                      indicator: <Loader />,
                    }}
                    dataSource={resultSet ? resultSet.rawData() : []}
                    rowKey="CommodityExchange.id"
                    pagination={false}
                    size="small"
                    scroll={{ y: 280 }}
                    style={{ height: 300 }}
                  >
                    <Table.Column
                      title="Наименование"
                      dataIndex="CommodityExchange.exchangeName"
                      width={100}
                      sorter={(a, b) =>
                        a["CommodityExchange.exchangeName"].localeCompare(
                          b["CommodityExchange.exchangeName"]
                        )
                      }
                    />
                    <Table.Column
                      title="Источник данных"
                      dataIndex="CommodityExchange.dataSource"
                      width={100}
                      sorter={(a, b) =>
                        a["CommodityExchange.dataSource"].localeCompare(
                          b["CommodityExchange.dataSource"]
                        )
                      }
                    />
                    <Table.Column
                      title="Дата обновления"
                      dataIndex="CommodityExchange.date"
                      render={(d) => formatDate(d)}
                      width={100}
                      sorter={(a, b) =>
                        a["CommodityExchange.date"].localeCompare(
                          b["CommodityExchange.date"]
                        )
                      }
                    />
                    <Table.Column
                      title="Средняя цена в долларах"
                      dataIndex="CommodityExchange.usdAvg"
                      width={100}
                    />
                    <Table.Column
                      title="Средняя цена в евро"
                      dataIndex="CommodityExchange.euroAvg"
                      width={100}
                    />
                    <Table.Column
                      title="Средняя цена в фунтах стерлинга"
                      dataIndex="CommodityExchange.poundAvg"
                      width={100}
                    />
                  </Table>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
