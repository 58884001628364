import React, { Component } from "react";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
export default class RadarChart extends Component {
  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { id, data, nameKey, dataKey } = this.props;
    /* Create chart instance */
    let chart = am4core.create(id, am4charts.RadarChart);
    chart.data = data;

    /* Create axes */
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = nameKey;
    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;
    label.tooltipText = "{category}";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
    valueAxis.renderer.axisFills.template.fillOpacity = 0.05;

    /* Create and configure series */
    let series = chart.series.push(new am4charts.RadarSeries());
    series.dataFields.valueY = dataKey;
    series.dataFields.categoryX = nameKey;
    series.name = dataKey;
    series.strokeWidth = 3;

    chart.cursor = new am4charts.RadarCursor();

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: "100%", height }} />;
  }
}

RadarChart.defaultProps = {
  nameKey: "category",
  dataKey: "value",
  showLegend: true,
  height: "480px",
};

RadarChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string,
  dataKey: PropTypes.string,
  showLegend: PropTypes.bool,
  height: PropTypes.number,
};
