import React, { Component } from "react";
import styles from "./index.module.scss";

const COLORS = [
  "#36e6bf",
  "#b422ff",
  "#58a42a",
  "#1a75de",
  "#01e0ff",
  "#ffaa01",
  "#ff99cb",
  "#FFAA00",
  "#FC3995",
];

export default class ProgressBar extends Component {
  render() {
    const { title, data, colors, category, value } = this.props;
    const colorsArr = colors ? colors : COLORS;
    const sum = data
      .map((d) => d["Appeals.appealsNum"])
      .reduce((a, b) => Number(a) + Number(b), 0);

    return (
      <div className={styles.progressbar}>
        <div className={styles.progressbarHeader}>
          <div className={styles.progressbarTitle}>{title}</div>
          <div className={styles.progressbarTotal}>{sum}</div>
        </div>

        <div className={styles.progressbarBars}>
          {data.map((d, i) => (
            <div
              style={{
                width: `${(Number(d[value]) / sum) * 100}%`,
                background: colorsArr[i],
              }}
              className={styles.progressbarBar}
            ></div>
          ))}
        </div>

        <table className={styles.progressbarStats}>
          <tbody>
            {data.map((d, i) => (
              <tr className={styles.progressbarStat}>
                <td>{d[category]}</td>
                <td>
                  <div
                    className={styles.progressbarWrapper}
                    style={{ borderColor: colorsArr[i] }}
                  >
                    <div
                      style={{
                        background: colorsArr[i],
                        padding: "0.1vw 0.6vw",
                        width: `${(Number(d[value]) / sum) * 100}%`,
                      }}
                    >
                      <span>
                        {((Number(d[value]) / sum).toFixed(2) * 100).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </td>
                <td>{d[value]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
