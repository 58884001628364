export const PIE_COLORS = [
  "#2AA6FF",
  "#FF7324",
  "#FFC224",
  "#E94D4D",
  "#CAE9FF",
];

export const CHART_MAIN_COLOR = "#2BA6FF";

export const CHART_SECONDARY_COLOR = "#CAE9FF";

export const FULL_COLOR_ARRAY = [
  "#2AA6FF33",
  "#CAE9FF33",
  "#FF732433",
  "#FFC22433",
  "#4D53E933",
  "#AC24FF33",
  "#E94D4D33",
  "#F256C633",
  "#FFE19433",
  "#FFCBAE33",
  "#9DFFB933",
  "#8E84FF33",
];
