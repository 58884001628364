import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";

import cubejs from "../../../cube";

import ProgressBar from "../../../components/ProgressBar";
import styles from "./appeals.module.scss";

const cubejsApi = cubejs({ appId: 1 });

export default class AppealsByStatus extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
  };

  render() {
    const { range } = this.state;

    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];

    return (
      <div className={styles.appeals_wrapper}>
        <div className={styles.appeals_wrapper_title}>По статусам</div>
        <div className={styles.appeals_wrapper_content}>
        <QueryRenderer
          query={{
            measures: ["Appeals.appealsNum"],
            dimensions: ["Appeals.theStatus"],
            filters,
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) return null;
            const data = resultSet.rawData();
            return (
              <div>
                <ProgressBar
                  category="Appeals.theStatus"
                  value="Appeals.appealsNum"
                  data={data}
                  colors={[
                    "#b422ff",
                    "#58a42a",
                    "#1a75de",
                    "#01e0ff",
                    "#ffaa01",
                    "#ff99cb",
                    "#FFAA00",
                    "#FC3995",
                  ]}
                />
              </div>
            );
          }}
        />
        </div>
      </div>
    );
  }
}
