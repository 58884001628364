import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Row, Col, Icon, Divider, Statistic, Card } from "antd";

import cubejs from "../../../cube";
import styles from "./weather.module.scss";

const cubejsApi = cubejs({ appId: 1 });
const todayDimension = {
  dimension: "WeatherView.validDate",
  dateRange: [moment().startOf("day"), moment().endOf("day")],
  granularity: "day",
};
const weekDimension = {
  dimension: "WeatherView.validDate",
  dateRange: [moment().startOf("day"), moment().add(4, "d").endOf("day")],
  granularity: "day",
};

export default class Ecology2 extends Component {
  state = {
    selectedCity: 343,
    notifications: [],
    filtersData: {
      "WeatherView.region": {
        key: "WeatherView.region",
        title: "Город",
        options: [],
        values: ["Караганда Г.А."],
      },
    },
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({
      filtersData,
    });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  handleChange = (value) => {
    this.setState({ selectedCity: value });
  };

  render() {
    const { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <div>
        {/* <h1 className="page-title">Погода</h1> */}

        {/* <Row gutter={20} style={{ marginBottom: 20 }}>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <Col span={6} key={i}>
                <Select
                  placeholder={filter.title}
                  onChange={(value) => this.applyFilters(filter.key, [value])}
                  size="small"
                  allowClear={false}
                  style={{ width: "100%" }}
                  defaultValue="Караганда Г.А."
                >
                  {filter.options.map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            );
          })}
        </Row> */}

        <Row gutter={10} type="flex">
          <Col span={24}>
            <Row
              gutter={20}
              style={{
                marginBottom: 20,
                textAlign: "center",
              }}
              type="flex"
            >
              <QueryRenderer
                query={{
                  measures: [
                    "WeatherView.dayTemperature",
                    "WeatherView.nightTemperature",
                    "WeatherView.dayCloudPct",
                    "WeatherView.dayHumidityPct",
                  ],
                  dimensions: ["WeatherView.dayUvDescription"],
                  timeDimensions: [todayDimension],
                  filters,
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  if (!resultSet) return null;
                  const data = resultSet.rawData();
                  const day = data["WeatherView.dayTemperature"]
                    ? Number(data["WeatherView.dayTemperature"])
                    : null;
                  const night = data["WeatherView.nightTemperature"]
                    ? Number(data["WeatherView.nightTemperature"])
                    : null;
                  const avg = day && night ? (day + night) / 2 : "--";
                  const diff = day && night ? day - night : "--";
                  return (
                    <Col span={8}>
                      <div className={styles.weather_wrapper}>
                        <div className={styles.weather_wrapper_title}>
                          Температура
                        </div>
                        <div className={styles.weather_wrapper_content}>
                          <div className="card-content">
                            <div className="circleData">
                              <div className="temperature-values">
                                <span className="temperature-max">
                                  {data["WeatherView.dayTemperature"]
                                    ? `${Number(
                                        data["WeatherView.dayTemperature"]
                                      ).toFixed(0)}°`
                                    : "--"}
                                </span>
                                <Divider type="vertical" />
                                <span className="temperature-min">
                                  {data["WeatherView.nightTemperature"]
                                    ? `${Number(
                                        data["WeatherView.nightTemperature"]
                                      ).toFixed(0)}°`
                                    : "--"}
                                </span>
                              </div>
                              <div className="temperature-current">{avg}°</div>
                              <div className="temperature-values">
                                <Icon type="arrow-up" />
                                <Divider type="vertical" />
                                0.5°
                              </div>
                            </div>
                            <div className="asideData">
                              <Statistic
                                title="Покрытие облаками"
                                className="light-blue value small-text"
                                value={
                                  data["WeatherView.dayCloudPct"]
                                    ? `${Number(
                                        data["WeatherView.dayCloudPct"]
                                      ).toFixed(1)}%`
                                    : "--"
                                }
                              />
                              <Statistic
                                title="Уровень ультрафиолетового излучения"
                                className="light-blue value small-text"
                                value={
                                  data["WeatherView.dayUvDescription"]
                                    ? data["WeatherView.dayUvDescription"]
                                    : "--"
                                }
                              />
                              <Statistic
                                title="Влажность воздуха"
                                className="light-green value small-text"
                                value={
                                  data["WeatherView.dayHumidityPct"]
                                    ? `${Number(
                                        data["WeatherView.dayHumidityPct"]
                                      ).toFixed(1)}%`
                                    : "--"
                                }
                              />
                              <Statistic
                                title="Разница температуры"
                                className="light-blue value small-text"
                                value={`${diff}°`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }}
              />
              <QueryRenderer
                query={{
                  measures: [
                    "WeatherView.dayTemperature",
                    "WeatherView.nightTemperature",
                    "WeatherView.dayWindSpeed",
                    "WeatherView.nightWindSpeed",
                  ],
                  dimensions: [
                    "WeatherView.dayWindDirCompass",
                    "WeatherView.nightWindDirCompass",
                    "WeatherView.dayOfWeek",
                    "WeatherView.weatherImage",
                  ],
                  timeDimensions: [weekDimension],
                  filters,
                  // order: {
                  //   "WeatherView.validDate": "desc"
                  // }
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  if (!resultSet) return null;
                  const data = resultSet.rawData();
                  return (
                    <Col span={16}>
                      <div className={styles.weather_wrapper}>
                        <div className={styles.weather_wrapper_title}>
                          Прогноз погоды
                        </div>
                        <div className={styles.weather_wrapper_content}>
                          <div className="card-content flex">
                            {data.map((d) => (
                              <Card className="day-forecast-card">
                                <div className="day-forecast-temperature">
                                  {d["WeatherView.dayOfWeek"]}
                                </div>
                                <div className="day-forecast-image">
                                  <img
                                    alt="Прогноз погоды"
                                    src={d["WeatherView.weatherImage"]}
                                  />
                                </div>
                                <div className="day-forecast-values">
                                  <span className="temperature-day">
                                    {d["WeatherView.dayTemperature"]
                                      ? `${Number(
                                          d["WeatherView.dayTemperature"]
                                        ).toFixed(1)}°`
                                      : "--"}
                                  </span>
                                  <Divider type="vertical" />
                                  <span className="temperature-night">
                                    {d["WeatherView.nightTemperature"]
                                      ? `${Number(
                                          d["WeatherView.nightTemperature"]
                                        ).toFixed(1)}°`
                                      : "--"}
                                  </span>
                                </div>
                                <div className="day-forecast-wind">
                                  <div className="day-forecast-wind-day">
                                    <span className="wind-value">
                                      {d["WeatherView.dayWindSpeed"]
                                        ? Number(
                                            d["WeatherView.dayWindSpeed"]
                                          ).toFixed(1)
                                        : "--"}
                                    </span>
                                    <span className="wind-metric"> km/h</span>
                                  </div>
                                  <Divider type="vertical" />
                                  <div className="day-forecast-wind-night">
                                    <span className="wind-value">
                                      {d["WeatherView.nightWindSpeed"]
                                        ? Number(
                                            d["WeatherView.nightWindSpeed"]
                                          ).toFixed(1)
                                        : "--"}
                                    </span>
                                    <span className="wind-metric"> km/h</span>
                                  </div>
                                </div>
                                <div className="day-forecast-side">
                                  <div className="day-forecast-side-day">
                                    <Icon type="pie-chart" />{" "}
                                    {d["WeatherView.dayWindDirCompass"]
                                      ? d["WeatherView.dayWindDirCompass"]
                                      : "--"}
                                  </div>
                                  <Divider type="vertical" />

                                  <div className="day-forecast-side-night">
                                    <Icon type="pie-chart" />{" "}
                                    {d["WeatherView.nightWindDirCompass"]
                                      ? d["WeatherView.nightWindDirCompass"]
                                      : "--"}
                                  </div>
                                </div>
                                {/* <div class="day-forecast-humidity">
                            <div className="day-forecast-humidity-day">
                              <span className="humidity-value"> 8.0 </span>
                              <span className="humidity-metric"> mm</span>
                            </div>
                            <Divider type="vertical" />
                            <div className="day-forecast-humidity-night">
                              <span className="humidity-value"> 8.0 </span>
                              <span className="humidity-metric"> mm</span>
                            </div>
                          </div> */}
                              </Card>
                            ))}
                          </div>
                          <p>weather.com</p>
                        </div>
                      </div>
                    </Col>
                  );
                }}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
