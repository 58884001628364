import React, { Component } from "react";
import moment from "moment";
import cubejs from "../../cube";
import {
  HorizontalBarChart,
  VerticalBarChart,
  PieChart,
  SingleNumber,
} from "../../components/visualizations";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { TabMainTitle } from "../../components/newDesign/TabMainTitle";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { DatePickerR } from "../../components/newDesign/DatePicker/DatePickerR";
import { CHART_MAIN_COLOR, PIE_COLORS } from "../../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });

class DamuMed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: [moment().subtract(1, "M"), moment().endOf("day")],
      count1: 0,
      count2: 0,
      count3: 0,
      chart1: [],
      chart2: [],
      chart3: [],
      chart4: [],
      chart5: [],
      chart6: [],
      isDataLoaded: false,
    };
  }

  dateChange = (val, key) => {
    this.setState(
      {
        [key]: moment(val),
        pieData: [],
      },
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.getDashData();
    this.getChartData();
  };

  getDashData = () => {
    const [startDate, endDate] = this.state.range;
    const range = this.state.range;
    let req = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: ["InfectiousArvi.currentValue"],
        filters: [
          {
            member: "InfectiousArvi.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: ["TraumatologySickCharts.sumCurrentValues"],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
    ];
    if (!startDate || !endDate) {
      req.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(req.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      this.setState({
        count1: data[0][0]["HospitalQueueCharts.currentValue"],
        count2: data[1][0]["InfectiousArvi.currentValue"],
        count3: data[2][0]["TraumatologySickCharts.sumCurrentValues"],
      });
    });
  };

  getChartData = async () => {
    this.setState({ isDataLoaded: false });
    const [startDate, endDate] = this.state.range;
    const range = this.state.range;
    const queries = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["HospitalQueueCharts.orgHealthCareNameRu"],
      },
      {
        measures: [
          "InfectiousArvi.currentValue",
          "InfectiousArvi.currentValue1",
        ],
        filters: [
          {
            member: "InfectiousArvi.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["InfectiousArvi.orgHealthCareNameRu"],
      },
      {
        measures: [
          "TraumatologySickCharts.currentValue",
          "TraumatologySickCharts.currentValue1",
          "TraumatologySickCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
      {
        measures: [
          "TraumatologySickCharts.currentValue",
          "TraumatologySickCharts.currentValue1",
          "TraumatologySickCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologySickCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["TraumatologySickCharts.orgHealthCareNameRu"],
      },
      {
        measures: ["PolyclinicQueueCharts.currentValue"],
        filters: [
          {
            member: "PolyclinicQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["PolyclinicQueueCharts.orgHealthCareNameRu"],
      },
      {
        measures: [
          "TraumatologyDeathesCharts.currentValue",
          "TraumatologyDeathesCharts.currentValue1",
          "TraumatologyDeathesCharts.currentValue2",
        ],
        filters: [
          {
            member: "TraumatologyDeathesCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["TraumatologyDeathesCharts.orgHealthCareNameRu"],
      },
    ];
    if (!startDate || !endDate) {
      queries.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(queries.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      this.setState({
        chart1: data[0].map((el) => ({
          category: el["HospitalQueueCharts.orgHealthCareNameRu"],
          value: el["HospitalQueueCharts.currentValue"],
        })),
        chart2: data[1].map((el) => ({
          category: el["InfectiousArvi.orgHealthCareNameRu"],
          value: el["InfectiousArvi.currentValue"],
          value1: el["InfectiousArvi.currentValue1"],
        })),
        chart3: [
          {
            category: "Количество ДТП",
            value: data[2][0]["TraumatologySickCharts.currentValue"],
            color: "#0D6ABF",
          },
          {
            category: "Количество Школьные",
            value: data[2][0]["TraumatologySickCharts.currentValue1"],
            color: "#41A4FF",
          },
          {
            category: "Количество Промышленные",
            value: data[2][0]["TraumatologySickCharts.currentValue2"],
            color: "#A0D1FF",
          },
        ],
        chart4: data[3].map((el) => ({
          category: el["TraumatologySickCharts.orgHealthCareNameRu"],
          "Количество ДТП": el["TraumatologySickCharts.currentValue"],
          "Количество Школьные": el["TraumatologySickCharts.currentValue1"],
          "Количество Промышленные": el["TraumatologySickCharts.currentValue2"],
        })),
        chart5: data[4].map((el) => ({
          category: el["PolyclinicQueueCharts.orgHealthCareNameRu"],
          value: el["PolyclinicQueueCharts.currentValue"],
        })),
        chart6: data[5].map((el) => ({
          category: el["TraumatologyDeathesCharts.orgHealthCareNameRu"],
          value: el["TraumatologyDeathesCharts.currentValue"],
          value1: el["TraumatologyDeathesCharts.currentValue1"],
          value2: el["TraumatologyDeathesCharts.currentValue2"],
        })),
        isDataLoaded: true,
      });
    });
  };

  render() {
    const { range } = this.state;
    return (
      <MainWrapper>
        <TabMainTitle>
          Статистика DamuMed на период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}> по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>

        <RowWrapper>
          <ChartCard>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                paddingBottom: 10,
                gap: 25,
              }}
            >
              <SingleNumber
                number={this.state.count1}
                title="Количество обращений в приемный покой"
              />
              <SingleNumber
                number={this.state.count2}
                title="Количество обратившихся с ОРВИ"
              />
              <SingleNumber
                number={this.state.count3}
                title="Количество обращений с травмами"
              />
            </div>
          </ChartCard>
        </RowWrapper>

        <FilterWrapper>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range }, () => this.getData());
              }}
              value={this.state.range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title="Количество обращений в приемный покой">
            <HorizontalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart1}
              id="chartID1"
              values={[{ title: "Количество обращений", key: "value" }]}
              colorsArr={[CHART_MAIN_COLOR]}
              height="400px"
            />
          </ChartCard>
          <ChartCard title="Количество обращений с ОРВИ">
            <VerticalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart2}
              id="chartID2"
              scrollEnd={0.5}
              rotate
              values={[
                { title: "Количество вчера", key: "value", color: "#EB895F" },
                {
                  title: "Количество сегодня",
                  key: "value1",
                  color: CHART_MAIN_COLOR,
                },
              ]}
              height="400px"
              scrollbarX
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title="Количество обращений с травмами">
            <PieChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart3}
              id="chartID3"
              colorList={["#2966a6", "#00bab5", "#daed4a"]}
              height="400px"
            />
          </ChartCard>
          <ChartCard title="Количество обращений с травмами">
            <VerticalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart4}
              id="chartID4"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: "Количество ДТП",
                  key: "Количество ДТП",
                  color: "#0D6ABF",
                },
                {
                  title: "Количество Школьные",
                  key: "Количество Школьные",
                  color: "#41A4FF",
                },
                {
                  title: "Количество Промышленные",
                  key: "Количество Промышленные",
                  color: "#A0D1FF",
                },
              ]}
              height="400px"
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Количество очередей в поликлиниках">
            <VerticalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart5}
              id="chartID5"
              scrollEnd={0.5}
              rotate
              values={[
                { title: "Количество", key: "value", color: CHART_MAIN_COLOR },
              ]}
              height="400px"
            />
          </ChartCard>
          <ChartCard title="Количество летальности от травматизма">
            <VerticalBarChart
              loading={!this.state.isDataLoaded}
              data={this.state.chart6}
              id="chartID6"
              scrollEnd={0.5}
              rotate
              values={[
                {
                  title: "Количество травм",
                  key: "value",
                  color: PIE_COLORS[0],
                },
                {
                  title: "Количество термических поражений",
                  key: "value1",
                  color: PIE_COLORS[1],
                },
                {
                  title: "Количество отравления",
                  key: "value2",
                  color: PIE_COLORS[2],
                },
              ]}
              height="400px"
              scrollbarX
            />{" "}
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}

export default DamuMed;
