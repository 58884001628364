/*
  Objects Array = [
    {
      ballon: {
        clusterCaption: "List of objects at the coordinates",
        balloonContentHeader,
        balloonContentBody,
        balloonContentFooter
      }
    }
  ]
  EVENTS 
  onClusterClick - returns array of objects at that cluster
  handleClick - return clicked object
*/

import React from "react";
import PropTypes from "prop-types";
import { Modal, Row } from "antd";
import { YMaps, Map, ZoomControl, FullscreenControl } from "react-yandex-maps";
import Loading from "../common/LoadingCss";

export default class YandexMapWrapDetail extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      loading: true,
      center: [49.807754, 73.088504],
      objects: [],
      visible: false,
      items: [],
      categories: [],
    };
    this.ymaps = null;
    this.map = null;
    this.clusterer = null;
  }

  hideDetails = (e) => {
    this.setState({
      visible: false,
    });
  };

  onApiAvaliable = (ymaps) => {
    this.ymaps = ymaps;
  };

  setMapControlInstanceRef = (ref) => {
    this.map = ref;
    setTimeout(() => {
      if (this.ymaps) {
        this.createCluster();
      }
      this.setState({ loading: false });
    }, 100);
  };

  onObjectClick = async (obj) => {
    const { idDataKey, handleClick } = this.props;
    if (handleClick) {
      handleClick(obj[idDataKey]);
    } else {
      this.loadDetails(obj);
    }
    // this.changeMapCenter(obj);
  };

  loadDetails = async (object) => {
    const { cubejsApi, query, idDataKey } = this.props;
    const objects = Array.isArray(object) ? object : [object];
    const resultSet = await cubejsApi.load({
      ...query,
      filters: [
        {
          dimension: idDataKey,
          operator: "equals",
          values: objects.map((o) => o[idDataKey].toString()),
        },
      ],
    });
    const items = resultSet.rawData();
    this.setState({
      visible: true,
      items,
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.map) {
      this.map.destroy();
    }
  }

  getDetails = async (objects) => {
    const { cubejsApi, query, idDataKey } = this.props;
    const resultSet = await cubejsApi.load({
      ...query,
      filters: [
        {
          dimension: idDataKey,
          operator: "equals",
          values: objects.map((o) => o[idDataKey].toString()),
        },
      ],
    });
    return resultSet.rawData();
  };

  createGeoObjects = (objects) => {
    const { xDataKey, yDataKey } = this.props;
    let geoObjects = [];
    if (!objects[0]) return [];
    objects.forEach((el) => {
      let coords = [el[yDataKey], el[xDataKey]];
      if (coords) {
        let obj = new this.ymaps.Placemark(coords, el.ballon, {
          iconColor: el.color ? el.color : "#3caa3c",
          preset: "islands#circleIcon",
        });
        obj.dataObject = el;
        obj.events.add("click", () => {
          this.onObjectClick(el);
        });
        geoObjects.push(obj);
      }
    });
    return geoObjects;
  };

  onClustererClick = (ev) => {
    const { onClusterClick } = this.props;
    if (!ev.get("target").dataObject) {
      const objects = ev
        .get("target")
        .getGeoObjects()
        .map((el) => el.dataObject);
      if (onClusterClick) {
        onClusterClick(objects);
      } else {
        this.loadDetails(objects);
      }
    }
  };

  createCluster = () => {
    const clusterer = new this.ymaps.Clusterer({
      hasBalloon: false,
      clusterDisableClickZoom: true,
      clusterIconLayout: "default#pieChart",
      clusterIconPieChartRadius: 25,
      clusterIconPieChartCoreRadius: 10,
      clusterIconPieChartStrokeWidth: 3,
      groupByCoordinates: false,
    });
    clusterer.events.add("click", (e) => {
      this.onClustererClick(e);
    });
    let geoObjects = this.createGeoObjects(this.state.objects);
    if (geoObjects[0]) clusterer.add(geoObjects);
    this.clusterer = clusterer;

    if (this.map)
      this.props.clusterer
        ? this.map.geoObjects.add(clusterer)
        : geoObjects.forEach((e) => this.map.geoObjects.add(e));
  };

  componentDidUpdate() {
    const { data, xDataKey, yDataKey, showDetails, type } = this.props;
    let filteredObjects = data.filter((el) => el[xDataKey] && el[yDataKey]);
    if (
      this.state.objects.length !== filteredObjects.length &&
      this._isMounted
    ) {
      let arr = [];
      this.getDetails(filteredObjects).then((r) => {
        r.forEach((item, index) => {
          arr.push({
            ...item,
            // ...data[index] Иногда приходят null по координатам
            ...filteredObjects[index],
          });
        });

        let cat = {
          "Blockposts.type": [
            { color: "#1c37fa", category: "Блокпост" },
            { color: "#ff0000", category: "Санитарный пост" },
          ],
          "CrimeKgf.theHardcode": [
            { color: "#2AA6FF", category: "Средней тяжести" },
            { color: "#FF7324", category: "Тяжкие" },
            { color: "#FFC224", category: "Небольшой тяжести" },
            { color: "#E94D4D", category: "Особо тяжкие" },
          ],
          "Appeals.theHardcode": [{ color: "#096dd9", category: "Оранжевый" }],
          "DtpKgf.fd1r141p1": [
            { color: "#2AA6FF", category: "трезвый" },
            { color: "#CAE9FF", category: "алкогольное опьянение" },
            { color: "#FFC224", category: "данные не заполнены" },
          ],
          "SchoolTypes.fullness": [
            {
              color: "#2BA6FF",
              category: "Профицит",
            },
            {
              color: "#3BBC56",
              category: "Норма",
            },
            {
              color: "#E94D4D",
              category: "Дефицит",
            },
          ],
        };

        if (showDetails) {
          arr.forEach((it) => {
            // it.color = cat.find(e => e.category === it["CrimeKgf.theHardcode"]).color;

            it.color = cat[type]?.find((e) => e.category === it[type])?.color;
          });
          // calculating percentage to display on legend of every category
          // eslint-disable-next-line no-unused-expressions
          cat[type]?.forEach((item) => {
            item.legendCount = arr.reduce((acc, current) => {
              if (item.category === current[type]) {
                return ++acc;
              }
              return acc;
            }, 0);
            if (arr.length && item.legendCount) {
              item.legendPercent = Number(
                (item.legendCount / arr.length) * 100
              ).toFixed(1);
            }
          });
          filteredObjects = arr;
        }

        this.setState(
          {
            objects: filteredObjects,
            categories: cat[type],
          },
          () => {
            if (this.clusterer) this.refreshClusterer();
            if (!this.props.showDefaultCity)
              this.changeMapCenter(filteredObjects[0]);
          }
        );
      });
    }
  }

  changeMapCenter = (el) => {
    const { xDataKey, yDataKey } = this.props;
    let coords = [el[yDataKey], el[xDataKey]];
    this.setState({ center: coords });
  };

  refreshClusterer = () => {
    this.clusterer.removeAll(); // Delete all geoObjects
    let geoObjects = this.createGeoObjects(this.state.objects);
    if (geoObjects[0]) {
      this.clusterer.add(geoObjects); // Add new GeoObjeccts
    }
  };

  render() {
    const { center, visible, items, categories } = this.state;
    let { renderDetail, height, zoom, showDetails } = this.props;
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        {showDetails && (
          <Row>
            <div className="map-legend">
              {categories.map((it, index) => (
                <div key={index}>
                  <span style={{ backgroundColor: it.color }} />
                  <p>
                    {it.category}: {it.legendCount} — {it.legendPercent}%
                  </p>
                </div>
              ))}
            </div>
          </Row>
        )}
        <YMaps onApiAvaliable={this.onApiAvaliable}>
          <Map
            state={{ center, zoom }}
            width="100%"
            height={height}
            instanceRef={this.setMapControlInstanceRef}
          >
            <ZoomControl
              options={{
                size: "small",
                zoomDuration: 1000,
              }}
            />
            <FullscreenControl />
          </Map>
        </YMaps>
        <Modal
          title="Детализация"
          visible={visible}
          onCancel={this.hideDetails}
          width="96%"
          footer={null}
          bodyStyle={{
            // height: "88vh",
            overflowY: "auto",
          }}
          centered
        >
          {renderDetail(items)}
        </Modal>
      </React.Fragment>
    );
  }
}

YandexMapWrapDetail.defaultProps = {
  query: {},
  dictionary: {},
  idDataKey: "id",
  xDataKey: "x",
  yDataKey: "y",
  zoom: 13,
  height: "55vh",
  clusterer: true,
};

YandexMapWrapDetail.propTypes = {
  cubejsApi: PropTypes.object,
  query: PropTypes.object,
  dictionary: PropTypes.object,
  data: PropTypes.array.isRequired,
  idDataKey: PropTypes.string,
  xDataKey: PropTypes.string,
  yDataKey: PropTypes.string,
  handleClick: PropTypes.func,
  onClusterClick: PropTypes.func,
  zoom: PropTypes.number,
  height: PropTypes.string,
  type: PropTypes.string,
  clusterer: PropTypes.bool,
};
