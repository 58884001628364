import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Table } from "antd";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import {
  CHART_MAIN_COLOR,
  PIE_COLORS,
  CHART_SECONDARY_COLOR,
} from "../components/newDesign/utils";

import cubejs from "../cube";

import Loader from "../components/Loader";
import {
  PieChart,
  HorizontalBarChart as HorizonBarChart,
} from "../components/visualizations";
import { generatePieData, generateBarData } from "../utils/visualizations";

const cubejsApi = cubejs({ appId: 1 });

export default class ForeignTrade extends Component {
  state = {
    filtersData: {
      "ForeignTrade.year": {
        key: "ForeignTrade.year",
        title: "Год",
        options: [],
        values: [],
        size: 4,
      },
    },
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>Внешняя торговля</TabMainTitle>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title="Среднее значение экспорта по странам (тыс. долл. США)">
            <QueryRenderer
              query={{
                measures: ["ForeignTrade.exportSum"],
                dimensions: ["ForeignTrade.kontinent"],
                filters: [
                  ...filters,
                  {
                    member: "ForeignTrade.exportSum",
                    operator: "set",
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="ForeignTradePie1"
                    colorList={PIE_COLORS}
                    {...generatePieData(resultSet)}
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Среднее значение импорта по странам (тыс. долл. США)">
            <QueryRenderer
              query={{
                measures: ["ForeignTrade.importSum"],
                dimensions: ["ForeignTrade.kontinent"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="ForeignTradePie2"
                    colorList={PIE_COLORS}
                    {...generatePieData(resultSet)}
                  />
                );
              }}
            />{" "}
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title=" Среднее значение экспорта и импорта по странам (тыс. долл. США)">
            <QueryRenderer
              query={{
                measures: [
                  "ForeignTrade.importAverage",
                  "ForeignTrade.exportAverage",
                ],
                dimensions: ["ForeignTrade.country"],
                filters,
                order: {
                  "ForeignTrade.exportAverage": "desc",
                },
                limit: 20,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="HorizonBarChart"
                    colorsArr={[CHART_MAIN_COLOR, CHART_SECONDARY_COLOR]}
                    {...generateBarData(resultSet)}
                    // showValues
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Таблица">
            <QueryRenderer
              query={{
                measures: [
                  "ForeignTrade.importSum",
                  "ForeignTrade.exportSum",
                  "ForeignTrade.turnover",
                ],
                dimensions: [
                  "ForeignTrade.id",
                  "ForeignTrade.country",
                  "ForeignTrade.year",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <Table
                    loading={{
                      spinning: !resultSet,
                      indicator: <Loader />,
                    }}
                    dataSource={resultSet ? resultSet.rawData() : []}
                    rowKey="ForeignTrade.id"
                    pagination={false}
                    size="small"
                    scroll={{ y: 400 }}
                  >
                    <Table.Column
                      title="Страна"
                      dataIndex="ForeignTrade.country"
                      width={200}
                      sorter={(a, b) =>
                        a["ForeignTrade.country"].localeCompare(
                          b["ForeignTrade.country"]
                        )
                      }
                    />
                    <Table.Column
                      title="Год"
                      dataIndex="ForeignTrade.year"
                      width={100}
                      sorter={(a, b) =>
                        a["ForeignTrade.year"] - b["ForeignTrade.year"]
                      }
                    />
                    <Table.Column
                      title="Экспорт"
                      dataIndex="ForeignTrade.exportSum"
                      width={100}
                    />
                    <Table.Column
                      title="Импорт"
                      dataIndex="ForeignTrade.importSum"
                      width={100}
                    />
                    <Table.Column
                      title="Товароборот"
                      dataIndex="ForeignTrade.turnover"
                      // width={100}
                    />
                  </Table>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
