import React, { Component } from "react";
import Filter from "./Filter";
import moment from "moment";
import { Row, Col, Table, Modal, Tooltip } from "antd";
import cubejs from "../../cube";
import PieChart from "../../components/visualizations/PieChart";
import ExportExcel from "../../components/ExportExcel";
import { numberWithCommas } from "../../utils/helpers";
const cubejsApi = cubejs({ appId: 1 });
const categoryTable = [
  {
    key: "key",
    label: "№",
    width: 20,
    sorter: function (a, b) {
      return a.key - b.key;
    },
  },
  {
    key: "category",
    label: "Исполнительный орган",
    width: 140,
    sorter: function (a, b) {
      return a["category"].localeCompare(b["category"]);
    },
  },
  {
    key: "countCategory",
    label: "Общее количество обращений",
    width: 120,
    sorter: function (a, b) {
      return a["countCategory"] - b["countCategory"];
    },
  },
  {
    key: "countAppeals",
    label: "Обращения ",
    width: 120,
    sorter: function (a, b) {
      return a["countAppeals"] - b["countAppeals"];
    },
  },
  {
    key: "countIncident",
    label: "Инцидент ",
    width: 120,
    sorter: function (a, b) {
      return a["countIncident"] - b["countIncident"];
    },
  },
  {
    key: "countInfo",
    label: "Запрос информации ",
    width: 120,
    sorter: function (a, b) {
      return a["countInfo"] - b["countInfo"];
    },
  },
];
const subCategoryTable = [
  {
    key: "key",
    label: "№",
    width: 20,
    sorter: function (a, b) {
      return a.key - b.key;
    },
  },
  {
    key: "subCategory",
    label: "Подкатегория",
    width: 100,
    sorter: function (a, b) {
      return a["subCategory"].localeCompare(b["subCategory"]);
    },
  },
  {
    key: "countCategory",
    label: "Общее количество обращений",
    width: 100,
    sorter: function (a, b) {
      return a["countCategory"] - b["countCategory"];
    },
  },
  {
    key: "countAppeals",
    label: "Обращения",
    width: 100,
    sorter: function (a, b) {
      return a["countAppeals"] - b["countAppeals"];
    },
  },
  {
    key: "countIncident",
    label: "Инцидент",
    width: 100,
    sorter: function (a, b) {
      return a["countIncident"] - b["countIncident"];
    },
  },
  {
    key: "countInfo",
    label: "Запрос информации",
    width: 100,
    sorter: function (a, b) {
      return a["countInfo"] - b["countInfo"];
    },
  },
];
const colors = [
  { category: "Запрос информации", color: "#12CAD6" },
  { category: "Обращение", color: "#22777c" },
  { category: "Инцидент", color: "#E4F9FF" },
];

class NewAppeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: [
        moment().utc(6).startOf("month").startOf("day"),
        moment().utc(6).endOf("day"),
      ],
      category: undefined,
      pieData: [],
      modalVisible: false,
      tableData: [],
      tableHeader: categoryTable,
      modalData: [],
      region: undefined,
      district: undefined,
      loading: true,
    };
  }

  dateChange = (val, key) => {
    let tmpDate = val;
    if (val === null) {
      if (key === "startDate") {
        tmpDate = moment("2019-11-25 00:00:00");
      }
      if (key === "endDate") {
        tmpDate = moment();
      }
      this.setState({ [key]: tmpDate, pieData: [] }, () => this.getData());
    } else {
      this.setState(
        {
          [key]: tmpDate,
          pieData: [],
        },
        () => {
          this.getData();
        }
      );
    }
  };

  changeCategories = (val) => {
    this.setState(
      {
        category: val,
        pieData: [],
      },
      () => this.getData()
    );
  };

  changeRegion = (val) => {
    this.setState(
      {
        region: val,
        pieData: [],
      },
      () => this.getData()
    );
  };

  changeDistrict = (val) => {
    this.setState(
      {
        district: val,
        pieData: [],
      },
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.getPieData();
    this.getTableData();
  };

  getPieData = async () => {
    this.setState({ loading: true });
    const query = {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [{ dimension: "Appeals.submissionDate" }],
      dimensions: ["Appeals.appealType"],
      filters: [],
      renewQuery: true,
    };
    if (this.state.range) {
      query.filters.push({
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: this.state.range,
      });
    }
    if (this.state.category) {
      query.filters.push({
        dimension: "Appeals.category",
        operator: "equals",
        values: [this.state.category],
      });
    }
    if (this.state.region) {
      query.filters.push({
        dimension: "Appeals.region",
        operator: "equals",
        values: [this.state.region],
      });
    }
    if (this.state.district) {
      query.filters.push({
        dimension: "Appeals.district",
        operator: "equals",
        values: [this.state.district],
      });
    }
    const resultSet = await cubejsApi.load(query);
    let pieData = resultSet.rawData().map((e) => ({
      category: e["Appeals.appealType"],
      value: +e["Appeals.appealsNum"],
    }));
    const total = pieData.reduce((a, b) => a + b.value, 0);
    pieData = pieData.map((e) => ({
      ...e,
      percent: ((e.value * 100) / total).toFixed(),
      color: colors.find((i) => i.category === e.category).color,
    }));

    this.setState({ pieData, loading: false });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  getTableData = async () => {
    const query = {
      measures: [
        "Appeals.appealsNum",
        "Appeals.appealsCategory",
        "Appeals.appealsIncident",
        "Appeals.appealsInfo",
      ],
      timeDimensions: [{ dimension: "Appeals.submissionDate" }],
      dimensions: ["Appeals.category"],
      filters: [],
      renewQuery: true,
    };
    if (this.state.range) {
      query.filters.push({
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: this.state.range,
      });
    }
    let tableHeader = categoryTable;
    if (this.state.category) {
      query.filters.push({
        dimension: "Appeals.category",
        operator: "equals",
        values: [this.state.category],
      });
      query.dimensions.push("Appeals.subCategory");
      tableHeader = subCategoryTable;
    }
    if (this.state.region) {
      query.filters.push({
        dimension: "Appeals.region",
        operator: "equals",
        values: [this.state.region],
      });
    }
    if (this.state.district) {
      query.filters.push({
        dimension: "Appeals.district",
        operator: "equals",
        values: [this.state.district],
      });
    }
    const resultSet = await cubejsApi.load(query);
    let tableData = resultSet
      .rawData()
      .map((e, index) => ({
        key: ++index,
        category: e["Appeals.category"],
        countCategory: +e["Appeals.appealsNum"],
        countAppeals: +e["Appeals.appealsCategory"],
        countIncident: +e["Appeals.appealsIncident"],
        countInfo: +e["Appeals.appealsInfo"],
        subCategory: e["Appeals.subCategory"],
      }))
      .filter((e) => e.category);

    this.setState({ tableData, tableHeader });
  };

  rowClicked = (val) => {
    if (this.state.category) {
      let request = {
        filters: [
          {
            dimension: "Appeals.category",
            operator: "equals",
            values: [val.category],
          },
          {
            dimension: "Appeals.subCategory",
            operator: "equals",
            values: [val.subCategory],
          },
        ],
        dimensions: [
          "Appeals.appealNum",
          "Appeals.fio",
          "Appeals.status",
          "Appeals.category",
          "Appeals.subCategory",
          "Appeals.executor",
          "Appeals.status",
          "Appeals.appealText",
          "Appeals.expired",
          "Appeals.submissionDate",
          "Appeals.submissionTime",
          "Appeals.appealType",
        ],
        renewQuery: true,
      };
      if (this.state.range) {
        request.filters.push({
          member: "Appeals.submissionDate",
          operator: "inDateRange",
          values: this.state.range,
        });
      }
      cubejsApi.load(request).then((r) => {
        const indexedModalData = r.rawData().map((el, id) => {
          return { ...el, key: id + 1 };
        });
        this.setState({
          modalData: indexedModalData.map((el) => ({
            ...el,
            "Appeals.submissionDate": moment(
              el["Appeals.submissionDate"]
            ).format("YYYY-MM-DD"),
          })),
          modalVisible: true,
          subCategory: val.subCategory,
        });
      });
    } else
      this.setState(
        {
          category: val.category,
          subCategory: val.subCategory,
        },
        () => this.getData()
      );
  };

  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  resetFilter = () => {
    this.setState(
      {
        category: undefined,
        region: undefined,
        district: undefined,
      },
      () => this.getData()
    );
  };

  renderRowColorAndWordBreak(text, record, maxWidth) {
    return {
      props: {
        style: { color: record.color },
      },
      children: (
        <Tooltip title={text}>
          <div
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: maxWidth,
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    };
  }

  render() {
    const { tableData, category, subCategory, range } = this.state;
    let count = tableData.reduce((a, b) => a + b.countCategory, 0);
    if (category)
      count = tableData
        .filter((e) => e.category === category)
        .reduce((a, b) => a + b.countCategory, 0);
    return (
      <div className="p-10">
        <Row type="flex" justify="space-between" align="bottom">
          {/* <Col span={2}>
            <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"]
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div>
                    <SingleNumber
                      number={+getSingleValue(resultSet, "Appeals.appealsNum")}
                      title="Всего заявок с момента запуска"
                    />
                  </div>
                );
              }}
            />
          </Col> */}
          <Col span={20}>
            <Filter
              changeCategories={this.changeCategories}
              category={this.state.category}
              resetFilter={this.resetFilter}
              range={this.state.range}
              handleRange={this.handleRange}
              changeRegion={this.changeRegion}
              region={this.state.region}
              changeDistrict={this.changeDistrict}
              district={this.state.district}
            />
          </Col>
          <Col span={2} justify="end" style={{ textAlign: "right" }}>
            <ExportExcel
              filename={`Отчет по обращениям граждан ${
                category !== undefined ? "(" + category + ")" : ""
              } за ${range[0].format(
                "YYYY-MM-DD HH:mm:ss"
              )} – ${range[1].format("YYYY-MM-DD HH:mm:ss")}`}
              data={tableData}
              fields={[
                { title: "Категория", dataIndex: "category" },
                { title: "Подкатегория", dataIndex: "subCategory" },
                {
                  title: "Общее количество обращений",
                  dataIndex: "countCategory",
                },
                { title: "Обращения", dataIndex: "countAppeals" },
                { title: "Инцидент", dataIndex: "catecountIncidentgory" },
                { title: "Запрос информации", dataIndex: "countInfo" },
              ]}
            />
          </Col>
        </Row>

        <h2 align="center" style={{ margin: "20px" }}>
          Статистические данные поступивших обращений по направлениям
        </h2>

        {category && (
          <h4 align="center" style={{ margin: "10px" }}>
            Количество обращений по категории {category}: {count}
          </h4>
        )}
        <Row>
          <Col span={8}>
            {/* <h3 align="center">Количество заявок</h3> */}
            <PieChart
              id="pieChartID"
              showTotal
              totalFontSize={16}
              loading={this.state.loading}
              data={this.state.pieData}
              showLegend={false}
              disableMovement
              height="400px"
            />
            <div className="pie-legend" style={{ textAlign: "center" }}>
              <ul>
                {this.state.pieData.map((e, i) => (
                  <li key={i}>
                    <span style={{ backgroundColor: e.color }}></span>
                    {e.category}:
                    <b>
                      {numberWithCommas(e.value, " ")} - {e.percent}%
                    </b>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col span={16}>
            <Table
              loading={!this.state.tableData[0]}
              dataSource={this.state.tableData}
              // scroll={{ y: "70vh" }}
              // size="small"
              onRow={(record) => ({ onClick: () => this.rowClicked(record) })}
              align="left"
              size="small"
              scroll={{ y: "57vh" }}
              pagination={{
                defaultPageSize: 100,
                pageSizeOptions: ["10", "20", "50", "100"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
            >
              {this.state.tableHeader.map((item, index) => (
                <Table.Column
                  title={item.label}
                  key={index}
                  dataIndex={item.key}
                  width={item.width}
                  sorter={item.sorter}
                  align="center"
                />
              ))}
            </Table>
          </Col>
        </Row>
        <Modal
          title={
            <Row
              style={{ paddingRight: 20 }}
              type="flex"
              justify="space-between"
            >
              <h5 style={{ maxWidth: "90%" }}>
                Количество обращений по категории
                <span style={{ color: "#5bbbff" }}> {category}</span>,
                подкатегории
                <span style={{ color: "#5bbbff" }}> {subCategory}</span>:{" "}
                {count}
                {`c ${this.state.range[0].format(
                  "YYYY-MM-DD"
                )} по ${this.state.range[1].format("YYYY-MM-DD")}`}
              </h5>
              <ExportExcel
                filename={`Детализация с${moment(range[0]).format(
                  "DD/MM/YY"
                )} по ${moment(range[1]).format("DD/MM/YY")}`}
                data={this.state.modalData}
                fields={[
                  { title: "№", dataIndex: "key" },
                  { title: "Номер заявки", dataIndex: "Appeals.appealNum" },
                  { title: "ФИО", dataIndex: "Appeals.fio" },
                  {
                    title: "Исполнительный орган",
                    dataIndex: "Appeals.category",
                  },
                  {
                    title: "Подкатегория обращения",
                    dataIndex: "Appeals.subCategory",
                  },
                  { title: "Исполняющий орган", dataIndex: "Appeals.executor" },
                  { title: "Статус обращения", dataIndex: "Appeals.status" },
                  {
                    title: "Дата обращения",
                    dataIndex: "Appeals.submissionDate",
                  },
                  {
                    title: "Время обращения",
                    dataIndex: "Appeals.submissionTime",
                  },
                  { title: "Тип обращения", dataIndex: "Appeals.appealType" },
                ]}
              />
            </Row>
          }
          visible={this.state.modalVisible}
          onCancel={this.hideModal}
          width="96%"
          footer={null}
          bodyStyle={{
            maxHeight: "818px",
            overflowY: "auto",
          }}
          centered
        >
          <Table
            rowClassName={(record) =>
              record["Appeals.expired"] === "Просрочено" ? "row error" : ""
            }
            columns={[
              { title: "№", dataIndex: "key", key: "key", width: "50px" },
              {
                title: "Номер заявки",
                dataIndex: "Appeals.appealNum",
                key: "appealNum",
                width: "140px",
              },
              {
                title: "ФИО",
                dataIndex: "Appeals.fio",
                key: "fio",
                width: "250px",
              },
              {
                title: "Исполнительный орган",
                dataIndex: "Appeals.category",
                key: "category",
                width: "250px",
              },
              {
                title: "Подкатегория обращения",
                dataIndex: "Appeals.subCategory",
                key: "subCategory",
                width: "250px",
              },
              {
                title: "Исполняющий орган",
                dataIndex: "Appeals.executor",
                key: "executor",
                width: "250px",
              },
              {
                title: "Текст обращения",
                dataIndex: "Appeals.appealText",
                key: "appealText",
                width: "250px",
                textWrap: "word-break",
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "35ch"),
                sorter: (a, b) => {
                  return a["Appeals.appealText"].localeCompare(
                    b["Appeals.appealText"]
                  );
                },
              },
              {
                title: "Статус обращения",
                dataIndex: "Appeals.status",
                key: "status",
                width: "250px",
              },
              {
                title: "Дата обращения",
                dataIndex: "Appeals.submissionDate",
                key: "submissionDate",
                width: "250px",
              },
              {
                title: "Время обращения",
                dataIndex: "Appeals.submissionTime",
                key: "submissionTime",
                width: "250px",
              },
              {
                title: "Тип обращения",
                dataIndex: "Appeals.appealType",
                key: "appealType",
                width: "250px",
              },
            ]}
            dataSource={this.state.modalData}
            size="small"
            scroll={{ y: "60vh", x: "max-content" }}
            pagination={{
              defaultPageSize: 100,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default NewAppeals;
