import React from "react";
import { Row, Col } from "antd";

import Crime from "./Crime";
import AppealsByStatus from "./Appeals/AppealsByStatus";
import AppealsBySource from "./Appeals/AppealsBySource";
import Kazgidromet from "./Kazgidromet";
import Weather from "./Weather";
import moment from "moment";
import styles from "./infopanel.module.scss";

import Statistics from "../Statistics";

const Infopanel = () => {
  return (
    <div className={styles.wrapper}>
      <Statistics />
      <div className={styles.wrapper_title}>Информационная панель</div>
      <Row type="flex" style={{ marginBottom: 10 }}>
        {/* here was 8 */}
        <Col span={24}>
          <div className={styles.criminals_wrapper}>
            Криминогенная обстановка c{" "}
            <span className="date-period">
              {moment().subtract(7, "d").format("DD.MM.YYYY")}
            </span>{" "}
            по{" "}
            <span className="date-period">{moment().format("DD.MM.YYYY")}</span>
          </div>
        </Col>
        <Col span={24}>
          <Crime />
        </Col>
        {/* <Col span={12} style={{ padding: "5px" }}>
          <Card
            title={
              <>
                <small style={{ float: "right" }}>
                  С{" "}
                  <span className="date-period">
                    {moment()
                      .subtract(30, "d")
                      .format("DD.MM.YYYY")}{" "}
                  </span>
                  по
                  <span className="date-period">
                    {moment().format("DD.MM.YYYY")}
                  </span>
                </small>
                <div>ДТП</div>
              </>
            }
            className="infocard"
          >
            <Dtp />
            <div style={{ textAlign: "right" }}>
              Комитет по правовой статистике и специальным учетам
            </div>
          </Card>
        </Col> */}
      </Row>

      {/* <Col span={6}>
          <Card title="Мониторинг государственных закупок" className="infocard">
            <MGZ />
          </Card>
        </Col> */}

      <Row type="flex" style={{ marginBottom: 10 }}>
        <Col span={24}>
          <div className={styles.criminals_wrapper}>
            Обращения граждан c{" "}
            <span className="date-period">
              {moment().subtract(7, "d").format("DD.MM.YYYY")}
            </span>{" "}
            по{" "}
            <span className="date-period">{moment().format("DD.MM.YYYY")}</span>
          </div>
          <Row gutter={10} type="flex">
            <Col span={12}>
              <AppealsByStatus />
            </Col>
            <Col span={12}>
              <AppealsBySource />
            </Col>
          </Row>
        </Col>

        {/* <Col span={14} style={{ padding: "5px" }}>
          <Card
            title={
              <>
                <small style={{ float: "right" }}>
                  С{" "}
                  <span className="date-period">
                    {moment()
                      .subtract(7, "d")
                      .format("DD.MM.YYYY")}{" "}
                  </span>
                  по
                  <span className="date-period">
                    {moment().format("DD.MM.YYYY")}
                  </span>
                </small>
                <div>Диспетчеризация общественного транспорта</div>
              </>
            }
            className="infocard"
          >
            <Transport />
            <div style={{ textAlign: "right" }}>Автопарк №3</div>
          </Card>
        </Col> */}
      </Row>

      <Row type="flex">
        <Col span={24}>
          <div className={styles.criminals_wrapper}>Погода</div>
          <Weather />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className={styles.kazgidromet_wrapper}>
            <div className={styles.kazgidromet_wrapper_title}>Казгидромет</div>
            <div className={styles.kazgidromet_wrapper_content}>
            <Kazgidromet />
            <p>РГП "Казгидромет"</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Infopanel;
