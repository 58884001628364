import React from "react";
import { Col, Row, Table } from "antd";
import ExportExcel from "../../components/ExportExcel";
import { columns, fields } from "./utils";

function Details({ data }) {
  return (
    <div>
      <Row type="flex" justify="space-between">
        <Col>
          <h3>Общее количество обращений : {data.length}</h3>
        </Col>
        <Col style={{ paddingRight: 30 }}>
          <ExportExcel
            filename={`Карта обращений`}
            data={data}
            fields={fields}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        size="small"
        scroll={{ y: "60vh", x: "max-content" }}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
        }}
        dataSource={data}
      />
    </div>
  );
}

export default Details;
