import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import moment from "moment";

import cubejs from "../cube";

import { LineChart, PieChart } from "../components/visualizations";
import { generateLineData, generatePieData } from "../utils/visualizations";
import { rangeList } from "../constants";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import { CheckboxGroup } from "../components/newDesign/CheckboxGroup";
import {
  CHART_MAIN_COLOR,
  CHART_SECONDARY_COLOR,
} from "../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });
export default class TrafficFlows extends Component {
  state = {
    filtersData: {
      "TransportgraphView.direction": {
        key: "TransportgraphView.direction",
        title: "Направление",
        options: [],
        values: [],
        shown: true,
      },
      "TransportgraphView.groupPlace": {
        key: "TransportgraphView.groupPlace",
        title: "Группировка",
        options: [],
        values: [],
        col: 8,
        shown: false,
      },
    },
    range: [moment().subtract(7, "d"), moment()],
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  changeRange = (value) =>
    this.setState({
      range: [moment(value), moment()],
    });

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "TransportgraphView.dateEvent",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Анализ входящих и исходящих транспортных потоков на период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>

        <FilterWrapper>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeList["Неделя"].toString()}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="title"
              valueKey="id"
              list={Object.entries(rangeList).map(([key, value]) => ({
                id: value.toString(),
                title: key,
              }))}
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard
            title="Количество проезжающих машин по дням"
            style={{ flex: 2 }}
          >
            <QueryRenderer
              query={{
                measures: ["TransportgraphView.countnumberSum"],
                dimensions: ["TransportgraphView.dateEvent.day"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="TrafficFlowsTotalCountByDays"
                    {...generateLineData(resultSet)}
                    dateAxis
                    filled
                    rotate
                    height="500px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Среднее значение по выбранным дням">
            <QueryRenderer
              query={{
                measures: ["TransportgraphView.countnumberSum"],
                dimensions: ["TransportgraphView.direction"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="TransportgraphViewDirection"
                    showLegend
                    {...generatePieData(resultSet)}
                    hiddenLabelText
                    colorList={[
                      CHART_MAIN_COLOR,
                      CHART_SECONDARY_COLOR,
                      "#FF7324",
                    ]}
                    height="500px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard
            title="Среднее количество проезжающих машин в час"
            style={{ flex: 2 }}
          >
            <QueryRenderer
              query={{
                measures: ["TransportgraphView.countnumberAvg"],
                dimensions: [
                  "TransportgraphView.groupPlace",
                  "TransportgraphView.hours",
                ],
                filters,
                order: {
                  "TransportgraphView.hours": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="TrafficFlowsAvgCarsByHour"
                    {...generateLineData(resultSet)}
                    filled
                    rotate
                    height="500px"
                    colorsArr={[
                      "#2AA6FF",
                      "#CAE9FF",
                      "#FF7324",
                      "#FFC224",
                      "#4D53E9",
                      "#AC24FF",
                      "#E94D4D",
                      "#F256C6",
                      "#FFE194",
                      "#FFCBAE",
                      "#9DFFB9",
                      "#8E84FF",
                      "#2AA6FF",
                      "#CAE9FF",
                      "#FF7324",
                      "#FFC224",
                      "#4D53E9",
                      "#AC24FF",
                      "#E94D4D",
                      "#F256C6",
                      "#FFE194",
                      "#FFCBAE",
                      "#9DFFB9",
                      "#8E84FF",
                      "#2AA6FF",
                      "#CAE9FF",
                      "#FF7324",
                    ]}
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard
            style={{
              background: "transparent",
            }}
            contentStyle={{ padding: 16, background: "transparent" }}
          >
            {filterNames.map((f, i) => {
              const filter = filtersData[f];
              return filter.shown ? (
                <SelectList
                  label={"Выбор направления:"}
                  mode="multiple"
                  placeholder={filter.title}
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  showKey="title"
                  valueKey="key"
                  list={filter.options.map((val) => ({
                    title: val,
                    key: val,
                  }))}
                />
              ) : null;
            })}

            <CheckboxGroup
              onChange={(values) =>
                this.applyFilters("TransportgraphView.groupPlace", values)
              }
              style={{
                maxHeight: 500,
                overflowY: "auto",
              }}
              list={filtersData["TransportgraphView.groupPlace"].options}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
