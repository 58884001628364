import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Row, Col } from "antd";

import cubejs from "../../../cube";
import styles from "./crime.module.scss";

import {
  LineChart,
  HorizontalBarChart as HorizonBarChart,
} from "../../../components/visualizations";
import {
  generateLineData,
  generateBarData,
} from "../../../utils/visualizations";
const cubejsApi = cubejs({ appId: 1 });

export default class Crime extends Component {
  state = {
    range: [moment().subtract(7, "d"), moment()],
  };

  render() {
    const { range } = this.state;

    let filters = [
      {
        member: "CrimeKgf.dateSover",
        operator: "inDateRange",
        values: range,
      },
    ];

    return (
      <div>
        <QueryRenderer
          query={{
            measures: ["CrimeKgf.count"],
            filters,
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) return null;
            return (
              <Row className={styles.crime_amount_wrapper}>
                <Col span={24}>
                  <div className={styles.crime_amount_wrapper_title}>
                    Количество преступлений:
                  </div>
                  <div className={styles.crime_amount_wrapper_subtitle}>
                    {resultSet.rawData()[0]["CrimeKgf.count"]}
                  </div>
                </Col>
              </Row>
            );
          }}
        />

        <Row gutter={10}>
          <Col span={12}>
            <div className={styles.general_dynamics}>
              <div className={styles.general_dynamics_title}>
                Общая динамика
              </div>
              <div className={styles.general_dynamics_content}>
                <QueryRenderer
                  query={{
                    measures: ["CrimeKgf.count"],
                    dimensions: ["CrimeKgf.dateSover.day"],
                    filters,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    return (
                      <LineChart
                        loading={!resultSet}
                        id="CrimeKgfCategoryCommon"
                        {...generateLineData(resultSet)}
                        dateAxis
                        rotate
                        showScrollbar={false}
                        showValues
                        wrapLabels
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Col>

          <Col span={12}>
            <div className={styles.general_dynamics}>
              <div className={styles.general_dynamics_title}>
                Преступления по категориям
              </div>
              <div className={styles.general_dynamics_content}>
                <QueryRenderer
                  query={{
                    measures: ["CrimeKgf.count"],
                    dimensions: ["CrimeKgf.crname"],
                    filters: filters.filter(
                      (f) => f.member !== "CrimeKgf.crname"
                    ),
                    order: {
                      "CrimeKgf.count": "asc",
                    },
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    return (
                      <HorizonBarChart
                        loading={!resultSet}
                        id="CrimeKgfCategory"
                        {...generateBarData(resultSet)}
                        colorsArr={["#2BA6FF"]}
                        showValues
                        scrollStart={0.8}
                        scrollEnd={1}
                        wrapLabels
                      />
                    );
                  }}
                />
                <p>
                Комитет по правовой статистике и специальным учетам
              </p>
              </div>
            </div>
          </Col>
          
        </Row>
      </div>
    );
  }
}
