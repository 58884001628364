import React, { Component } from "react";
import moment from "moment";
import { Statistic } from "antd";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";

export default class CardsSummary extends Component {
  state = {
    filtersData: {},
    range: [moment().subtract(30, "d"), moment()],
  };

  componentWillReceiveProps({ ambulance, emergency, police }) {
    this.setState({ ambulance, emergency, police }, this.updateList);
  }

  updateList = () => {
    const list = [
      {
        title: "112 - ДЧС",
        value: this.state.emergency,
        icon: "/icons/112.svg",
      },
      {
        title: "102 - Департамент полиции",
        value: this.state.police,
        icon: "/icons/102.svg",
      },
      {
        title: "103 - Скорая помощь",
        value: this.state.ambulance,
        icon: "/icons/103.svg",
      },
    ];

    this.setState({
      list,
    });
  };

  componentDidMount() {
    this.updateList();
  }

  render() {
    return (
      <RowWrapper>
        {this.state.list ? (
          this.state.list.map((item) => (
            <ChartCard title={item["title"]} style={{ height: 170 }}>
              <Statistic
                style={{ marginTop: 40, marginLeft: "44%" }}
                value={item["value"]}
                prefix={
                  <img
                    alt="Статистика"
                    style={{
                      width: "25px",
                      display: "block",
                      margin: "auto",
                    }}
                    src={item["icon"]}
                  />
                }
              />
            </ChartCard>
          ))
        ) : (
          <p>loading</p>
        )}
      </RowWrapper>
    );
  }
}
