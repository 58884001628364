import React from "react";
import { Statistic, Card, Row, Col } from "antd";

import cubejs from "../../../cube";
import { PieChart } from "../../../components/visualizations";
import { numberWithCommas } from "../../../utils/helpers";

const colors = ["#265e64", "#0FABBC", "#E4F9FF"];
const cubejsApi = cubejs({ appId: 1 });

const FromStart = ({ icons, toggleShowModal }) => {
  const [pieData, setPieData] = React.useState([]);
  const [cardData, setCardData] = React.useState({});

  let query = [
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Запрос информации"],
        },
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Инцидент"],
        },
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
    {
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType", "Appeals.source"],
      filters: [
        {
          dimension: "Appeals.appealType",
          operator: "contains",
          values: ["Обращение"],
        },
      ],
      order: {
        "Appeals.source": "asc",
      },
      renewQuery: true,
    },
  ];

  const getPieData = async () => {
    let response = await cubejsApi.load({
      measures: ["Appeals.appealsNum"],
      timeDimensions: [],
      dimensions: ["Appeals.appealType"],
      renewQuery: true,
    });

    let resultSet = response.rawData();
    let sum = resultSet.reduce(
      (a, b) => a + Number(b["Appeals.appealsNum"]),
      0
    );
    const data = resultSet.map((e) => ({
      ...e,
      percent: ((+e["Appeals.appealsNum"] / sum) * 100).toFixed(2),
    }));
    setPieData(data);
  };

  const getOtherData = async () => {
    query = query.map((e) => cubejsApi.load(e));
    Promise.all(query).then((r) => {
      setCardData([
        { title: "Запрос информации", data: r[0].rawData() },
        {
          title: "Инцидент",
          data: r[1].rawData(),
        },
        {
          title: "Обращение",
          data: r[2].rawData(),
        },
      ]);
    });
  };

  const getData = () => {
    getPieData();
    getOtherData();
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pieData[0]) {
    return null;
  }
  return (
    <Col span={12}>
      <div
        style={{
          background: "#323c48",
          padding: "10px",
        }}
      >
        <Row type="flex">
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              padding: 10,
              textTransform: "uppercase",
              marginBottom: 4,
            }}
          >
            с момента запуска
          </div>
        </Row>
        <Row type="flex">
          <Col span={10} style={{ marginBottom: 30 }}>
            <PieChart
              data={pieData || []}
              loading={pieData[0] && false}
              dataKey="Appeals.appealsNum"
              nameKey="Appeals.appealType"
              colorList={colors}
              id="first"
              showTotal
              showLegend={false}
              totalFontSize={16}
              disableMovement
              height="250px"
              // handleClick
              // cubejsApi={cubejsApi}
              // query={detailQuery}
              // renderDetail={renderDetail}
              // excelFields={fields}
              exportExcel
            />
            <div className="pie-legend">
              <ul>
                {pieData.map((e, i) => (
                  <li key={i}>
                    <span style={{ backgroundColor: colors[i] }}></span>
                    {e["Appeals.appealType"]}:
                    <b>
                      {numberWithCommas(e["Appeals.appealsNum"], " ")} —{" "}
                      {e.percent}%
                    </b>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col span={14}>
            <Row gutter={10}>
              {cardData[0] &&
                cardData.map((item) => (
                  <Col span={8} key={item.title}>
                    <div className="title-appeal">
                      <div>
                        <h4>{item.title}:</h4>
                      </div>
                      <div>
                        <h4>
                          {
                            pieData.find(
                              (e) => e["Appeals.appealType"] === item.title
                            )["Appeals.appealsNum"]
                          }
                        </h4>
                      </div>
                    </div>
                    {item.data.map((event, index) => (
                      <Card
                        onClick={() => {
                          toggleShowModal(event["Appeals.source"], item.title);
                        }}
                        key={index}
                        style={{ backgroundColor: "#2A323C" }}
                        className="appeal-numbers"
                      >
                        <Statistic
                          title={event["Appeals.source"]}
                          value={event["Appeals.appealsNum"]}
                          valueStyle={{ fontSize: "16px" }}
                          groupSeparator="&thinsp;"
                          prefix={
                            <img
                              style={{
                                width: "20px",
                                marginBottom: 5,
                                marginRight: 5,
                              }}
                              alt={event["Appeals.source"]}
                              src={"." + icons[event["Appeals.source"]]}
                            />
                          }
                        />
                      </Card>
                    ))}
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default FromStart;
