import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Table } from "antd";
import { rangeList } from "../constants";

import cubejs from "../cube";

import Loader from "../components/Loader";
import {
  SingleNumber,
  LineChart,
  HorizontalBarChart as HorizonBarChart,
} from "../components/visualizations";
import {
  getSingleValue,
  generateLineData,
  generateBarData,
} from "../utils/visualizations";
import { formatDate } from "../utils/helpers";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import { CHART_MAIN_COLOR } from "../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });

class ClinicVisitors extends Component {
  state = {
    filtersData: {
      "ClinicVisitors.clinicName": {
        key: "ClinicVisitors.clinicName",
        title: "Мед. учреждения",
        options: [],
        values: [],
      },
      "ClinicVisitors.doctor": {
        key: "ClinicVisitors.doctor",
        title: "Врачи",
        options: [],
        values: [],
      },
      "ClinicVisitors.number": {
        key: "ClinicVisitors.number",
        title: "Кабинет",
        options: [],
        values: [],
        col: 2,
      },
    },
    range: [moment().subtract(30, "d"), moment()],
  };

  changeRange = (value) =>
    this.setState({
      range: [moment(value), moment()],
    });

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));

    const responses = await Promise.all(reqs);
    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "ClinicVisitors.date",
        operator: "inDateRange",
        values: range.map((item) => moment(item).format("YYYY-MM-DD")),
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Посещаемость поликлиник на период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <RowWrapper>
          <ChartCard>
            <QueryRenderer
              query={{
                measures: [
                  "ClinicVisitors.visitorCount",
                  "ClinicVisitors.moCount",
                  "ClinicVisitors.doctorCount",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 20,
                      paddingBottom: 10,
                      gap: 25,
                    }}
                  >
                    <SingleNumber
                      number={getSingleValue(
                        resultSet,
                        "ClinicVisitors.visitorCount"
                      )}
                      title="Количество посещений"
                    />
                    <SingleNumber
                      number={getSingleValue(
                        resultSet,
                        "ClinicVisitors.moCount"
                      )}
                      title="Количество поликлиник"
                    />
                    <SingleNumber
                      number={getSingleValue(
                        resultSet,
                        "ClinicVisitors.doctorCount"
                      )}
                      title="Количество врачей"
                    />
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeList["Неделя"]}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="key"
              valueKey="value"
              list={Object.entries(rangeList).map(([key, value]) => ({
                key,
                value,
              }))}
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <ChartCard title="Посещаемость по дням">
            <QueryRenderer
              query={{
                measures: ["ClinicVisitors.visitorCount"],
                dimensions: ["ClinicVisitors.date.day"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="ClinicVisitorsCount"
                    {...generateLineData(resultSet)}
                    dateAxis
                    rotate
                    height={400}
                    filled
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Посещаемость в поликлиниках">
            <QueryRenderer
              query={{
                measures: ["ClinicVisitors.visitorCount"],
                dimensions: ["ClinicVisitors.clinicName"],
                filters,
                order: {
                  "ClinicVisitors.visitorCount": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="ClinicVisitorsClinicName"
                    {...generateBarData(resultSet)}
                    colorsArr={[CHART_MAIN_COLOR]}
                    showValues
                    scrollStart={0.5}
                    height={400}
                    scrollEnd={1}
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title="Таблица">
            <QueryRenderer
              query={{
                measures: ["ClinicVisitors.visitorCount"],
                dimensions: [
                  "ClinicVisitors.date.day",
                  "ClinicVisitors.clinicName",
                  "ClinicVisitors.doctor",
                  "ClinicVisitors.number",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <Table
                    loading={{
                      spinning: !resultSet,
                      indicator: <Loader />,
                    }}
                    dataSource={resultSet ? resultSet.rawData() : []}
                    // rowKey="ClinicVisitors.id"
                    pagination={true}
                    size="small"
                  >
                    <Table.Column
                      title="Дата"
                      dataIndex="ClinicVisitors.date"
                      // width={140}
                      render={(date) => formatDate(date)}
                    />
                    <Table.Column
                      title="Мед. учреждение"
                      dataIndex="ClinicVisitors.clinicName"
                      // width={200}
                    />
                    <Table.Column
                      title="Доктор"
                      dataIndex="ClinicVisitors.doctor"
                      // width={200}
                    />
                    <Table.Column
                      title="Кабинет"
                      dataIndex="ClinicVisitors.number"
                      // width={100}
                    />
                    <Table.Column
                      title="Посетители"
                      dataIndex="ClinicVisitors.visitorCount"
                      // width={100}
                    />
                  </Table>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}

export default ClinicVisitors;
