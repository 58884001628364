import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Infopanel from "./pages/Infopanel";
import CrimeStatistics from "./pages/CrimeStatistics";
import Dtp from "./pages/Dtp";
import Ecology from "./pages/Ecology";
import Ecology2 from "./pages/Ecology2";
import Weather from "./pages/Weather";
import Schools from "./pages/Schools";
import MGZ from "./pages/MGZ";

import FoodBasket from "./pages/FoodBasket";

import PublicTransport from "./pages/PublicTransport";
import ProductStructure from "./pages/ProductStructure";
import ParticipantCategory from "./pages/ParticipantCategory";
import ForeignTrade from "./pages/ForeignTrade";

import Appeals from "./pages/Appeals";
import CommodityExchange from "./pages/CommodityExchange";

import TrafficFlows from "./pages/TrafficFlows";
import HeatmapView from "./pages/HeatmapView";
import ClinicVisitors from "./pages/ClinicVisitors";

import "antd/dist/antd.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import NewAppeals from "./pages/NewAppeals";
import DamuMed from "./pages/DamuMed";
import AppealsMonitoring from "./pages/AppealsMonitoring";
import AppealsCitizen from "./pages/AppealsCitizen";
import OperationalSummary from "./pages/OperationalSummary";
import Calendar from "./pages/OperationalSummary/Calendar";
import CategoryAnalytics from "./pages/CategoryAnalytics";
import RegionAnalytics from "./pages/RegionAnalytics";
import DtpMap from "./pages/DtpMap";
import WeatherRP5 from "./pages/WeatherRP5";
import Currency from "./pages/Currency";
import AppealsMap from "./pages/AppealsMap/AppealsMap";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Infopanel} />
        <Route exact path="/crime" component={CrimeStatistics} />
        <Route exact path="/dtp" component={Dtp} />
        <Route exact path="/dtp-map" component={DtpMap} />
        <Route exact path="/ecology" component={Ecology} />
        <Route exact path="/ecology1" component={Ecology2} />
        <Route exact path="/weather" component={Weather} />
        <Route exact path="/schools" component={Schools} />
        <Route exact path="/new-appeals" component={NewAppeals} />
        <Route exact path="/mgz" component={MGZ} />
        <Route exact path="/foodbasket" component={FoodBasket} />
        <Route exact path="/damu-med" component={DamuMed} />
        <Route exact path="/public-transport" component={PublicTransport} />
        <Route exact path="/product-structure" component={ProductStructure} />
        <Route exact path="/currency" component={Currency} />
        <Route
          exact
          path="/participant-category"
          component={ParticipantCategory}
        />
        <Route exact path="/foreign-trade" component={ForeignTrade} />
        <Route exact path="/appeals" component={Appeals} />
        <Route exact path="/appeals-map" component={AppealsMap} />
        <Route exact path="/commodity-exchange" component={CommodityExchange} />
        <Route exact path="/traffic-flows" component={TrafficFlows} />
        <Route exact path="/heatmap-view" component={HeatmapView} />
        <Route exact path="/clinic-visitors" component={ClinicVisitors} />
        <Route exact path="/appeals-monitoring" component={AppealsMonitoring} />
        <Route exact path="/appeals-citizen" component={AppealsCitizen} />
        <Route
          exact
          path="/operational-summary"
          component={OperationalSummary}
        />
        <Route exact path="/events-calendar" component={Calendar} />
        <Route exact path="/category-analytics" component={CategoryAnalytics} />
        <Route exact path="/weather-rp5" component={WeatherRP5} />
        <Route exact path="/region-analytics" component={RegionAnalytics} />

        {/* <Route exact path="/statistics" component={Statistics} /> */}
      </Switch>
    );
  }
}

export default App;
