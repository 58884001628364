import React, { Component } from "react";
import { Icon } from "antd";
import { numberWithCommas } from "../../utils/helpers";
import styles from "./index.module.scss";

export default class Indicator extends Component {
  render() {
    const {
      title,
      icon,
      value,
      unit,
      direction,
      isPositive,
      period,
      children,
    } = this.props;
    return (
      <div
        className={styles.card}
        // onClick={onClick}
      >
        <div className={styles.cardHeading}>
          <img
            alt="Индикатор"
            src={icon}
            width="50px"
            height="50px"
            style={{ marginBottom: 10 }}
          />
          <h4 className={styles.cardTitle}>{title}</h4>
        </div>
        <div className={styles.cardBody}>
          <h2>
            <Icon
              type={`arrow-${direction === "up" ? "up" : "down"}`}
              style={{
                color: isPositive ? "#83FF49" : "#E94D4D",
                marginRight: 10,
              }}
            />
            <b style={{ color: isPositive ? "#83FF49" : "#E94D4D" }}>
              {numberWithCommas(value, " ")}
            </b>{" "}
            <small>{unit}</small>
          </h2>
          <p>
            {/* <b>{percentage}%</b>  */}
            {period}
          </p>
        </div>
        {children}
      </div>
    );
  }
}
