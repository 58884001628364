import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";

import cubejs from "../cube";

import {
  HorizontalBarChart as HorizonBarChart,
  LineChart,
} from "../components/visualizations";
import { generateBarData, generateLineData } from "../utils/visualizations";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import {
  CHART_MAIN_COLOR,
  CHART_SECONDARY_COLOR,
} from "../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });

export default class ProductStructure extends Component {
  state = {
    filtersData: {
      "ProductStructure.group": {
        key: "ProductStructure.group",
        title: "Группа",
        options: [],
        values: [],
        size: 6,
      },
      "ProductStructure.year": {
        key: "ProductStructure.year",
        title: "Год",
        options: [],
        values: [],
        size: 4,
      },
    },
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>Товарная структура</TabMainTitle>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>
        <RowWrapper>
          <ChartCard
            title="Среднее значение экспорта и импорта по группам товаров в тыс.
                долл. США"
          >
            <QueryRenderer
              query={{
                measures: [
                  "ProductStructure.importAverage",
                  "ProductStructure.exportAverage",
                ],
                dimensions: ["ProductStructure.group"],
                filters,
                order: {
                  "ProductStructure.exportAverage": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="HorizonBarChart"
                    {...generateBarData(resultSet)}
                    wrapLabels
                    height="900px"
                    colorsArr={[CHART_MAIN_COLOR, CHART_SECONDARY_COLOR]}
                  />
                );
              }}
            />
          </ChartCard>

          <div style={{ flex: 1 }}>
            <div>
              <ChartCard title="Товарная структура импорта в торговле по странам (тыс. долл. США)">
                <QueryRenderer
                  query={{
                    measures: ["ProductStructure.importSum"],
                    dimensions: [
                      "ProductStructure.group",
                      "ProductStructure.year",
                    ],
                    filters,
                    order: {
                      "ProductStructure.year": "asc",
                    },
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    return (
                      <LineChart
                        loading={!resultSet}
                        id="LineChartImport"
                        {...generateLineData(resultSet)}
                        filled
                        rotate
                        height="400px"
                      />
                    );
                  }}
                />
              </ChartCard>
            </div>
            <div>
              <ChartCard title="Товарная структура экспорта по странам (тыс. долл. США)">
                <QueryRenderer
                  query={{
                    measures: ["ProductStructure.exportSum"],
                    dimensions: [
                      "ProductStructure.group",
                      "ProductStructure.year",
                    ],
                    filters,
                    order: {
                      "ProductStructure.year": "asc",
                    },
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    return (
                      <LineChart
                        loading={!resultSet}
                        id="LineChartExport"
                        {...generateLineData(resultSet)}
                        filled
                        rotate
                        height="400px"
                      />
                    );
                  }}
                />
              </ChartCard>
            </div>
          </div>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
