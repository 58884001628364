import React, { Component } from "react";
import cubejs from "../../cube";
import { LineChart } from "../../components/visualizations";
import RadarChart from "../../charts/RadarChart";
import { dateGroups, KaragandaObl } from "../../constants";
import moment from "moment";
import YandexMapWrap from "../../charts/YandexMapWrap";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { SelectList } from "../../components/newDesign/SelectList";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";
import { DatePickerR } from "../../components/newDesign/DatePicker/DatePickerR";

const cubejsApi = cubejs({ appId: 1 });

export default class WeatherRP5 extends Component {
  state = {
    chart1: [],
    chart2: [],
    chart3: [],
    chart4: [],
    chart5: [],
    chart6: [],
    chart7: [],
    chart8: [],
    chart9: [],
    chart10: [],
    loading: true,
    filtersData: {
      "Rp5Stations.name": {
        key: "Rp5Stations.name",
        title: "Станция",
        options: [],
        values: ["г. Караганда"],
      },
    },
    range: [moment().subtract(9, "year").startOf("day"), moment()],
    polygon: [],
    granularity: "day",
    mapData: [],
  };

  async componentDidMount() {
    let polygonData = [
      {
        data: KaragandaObl.map((e) => e.map((el) => [el[1], el[0]])),
        balloonContent: "Карагандинская область",
      },
    ];
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({
      filtersData,
      polygon: polygonData,
    });
    this.getData();
  }

  changeRange = (value) => {
    this.setState(
      {
        granularity: value,
        loading: true,
      },
      () => this.getData()
    );
  };

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState(
      {
        filtersData: {
          ...filtersData,
          [filter]: {
            ...filtersData[filter],
            values,
          },
        },
        loading: true,
      },
      () => this.getData()
    );
  };

  getData = () => {
    const { filtersData, range, granularity } = this.state;
    const filterNames = Object.keys(filtersData);
    let filters = [
      {
        member: "WeatherRp5.dateTime",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });
    let requests = [
      {
        measures: ["WeatherRp5.avgTemp"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.windSpeed"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.count"],
        dimensions: ["WeatherRp5.dd"],
        order: { "WeatherRp5.dd": "asc" },
        filters,
      },
      {
        measures: ["WeatherRp5.overcast"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.precipitation"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.wet"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.tension"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.pointTemp"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.visibility"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        measures: ["WeatherRp5.snow"],
        dimensions: ["WeatherRp5.dateTime." + granularity],
        filters,
      },
      {
        dimensions: ["Rp5Stations.lat", "Rp5Stations.lon", "Rp5Stations.name"],
      },
    ];
    Promise.all(requests.map((el) => cubejsApi.load(el))).then((r) => {
      let data = r.map((e) => e.rawData());
      this.setState({
        chart1: data[0],
        chart2: data[1],
        chart3: data[2],
        chart4: data[3],
        chart5: data[4],
        chart6: data[5],
        chart7: data[6],
        chart8: data[7],
        chart9: data[8],
        chart10: data[9],
        mapData: data[10].map((e) => ({
          ...e,
          color: filtersData["Rp5Stations.name"].values.includes(
            e["Rp5Stations.name"]
          )
            ? "#ff0000"
            : "#3caa3c",
        })),
        loading: false,
      });
    });
  };

  render() {
    const { filtersData, range, polygon, mapData, granularity } = this.state;
    const filterNames = Object.keys(filtersData);
    return (
      <MainWrapper>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  onChange={(value) => this.applyFilters(filter.key, [value])}
                  allowClear
                  size="small"
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range, loading: true }, () => this.getData());
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={granularity}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="label"
              valueKey="granularity"
              list={dateGroups}
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <div style={{ width: "30%", height: "80vh" }}>
            <YandexMapWrap
              polygon={polygon}
              balloonContent="Карагандинская область"
              idDataKey="Rp5Stations.name"
              xDataKey="Rp5Stations.lon"
              yDataKey="Rp5Stations.lat"
              objects={this.state.loading ? [] : mapData}
              handleClick={(e) => {
                this.applyFilters("Rp5Stations.name", [e]);
              }}
              clusterer={false}
              showDefaultCity
              height="100%"
              zoom={6}
            />
          </div>
          <div
            style={{
              width: "69%",
              marginLeft: 10,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Температура воздуха (градусы Цельсия)"}
            >
              <LineChart
                id="chart1"
                loading={this.state.loading}
                data={this.state.chart1}
                values={[
                  {
                    key: "WeatherRp5.avgTemp",
                    title: "Температура воздуха (градусы Цельсия)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                filled
                dateAxis
                height="400px"
              />
            </ChartCard>
            <ChartCard
              title={
                "Cкорость ветра на высоте 10-12 метров над земной поверхностью, осредненная за 10-минутный период (метры в секунду)"
              }
            >
              <LineChart
                id="chart2"
                loading={this.state.loading}
                data={this.state.chart2}
                values={[
                  {
                    key: "WeatherRp5.windSpeed",
                    title: "Cкорость ветра(метры в секунду)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                dateAxis
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Роза ветров (направление ветра, румбы)"}
            >
              {!this.state.loading && (
                <RadarChart
                  data={this.state.chart3}
                  id="chart3"
                  nameKey="WeatherRp5.dd"
                  dataKey="WeatherRp5.count"
                  height="400px"
                />
              )}
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Общая облачность"}
            >
              <LineChart
                id="chart4"
                loading={this.state.loading}
                data={this.state.chart4}
                values={[
                  { key: "WeatherRp5.overcast", title: "Общая облачность" },
                ]}
                dateAxis
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Количество выпавших осадков (в миллиметрах)"}
            >
              <LineChart
                id="chart5"
                loading={this.state.loading}
                dateAxis
                data={this.state.chart5}
                values={[
                  {
                    key: "WeatherRp5.precipitation",
                    title: "Количество выпавших осадков (в миллиметрах)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Относительная влажность (в %)"}
            >
              <LineChart
                id="chart6"
                loading={this.state.loading}
                dateAxis
                data={this.state.chart6}
                values={[
                  {
                    key: "WeatherRp5.wet",
                    title: "Относительная влажность (в %)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Атмосферное давление (в миллиметрах)"}
            >
              <LineChart
                id="chart7"
                loading={this.state.loading}
                data={this.state.chart7}
                values={[
                  {
                    key: "WeatherRp5.tension",
                    title: "Атмосферное давление (в миллиметрах)",
                  },
                ]}
                dateAxis
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>

            <ChartCard
              title={
                "Температура точки росы на высоте 2 метра над поверхностью земли (градус Цельсия)"
              }
            >
              <LineChart
                id="chart8"
                loading={this.state.loading}
                data={this.state.chart8}
                values={[
                  {
                    key: "WeatherRp5.pointTemp",
                    title:
                      "Температура точки росы на высоте 2 метра над поверхностью земли (градус Цельсия)",
                  },
                ]}
                dateAxis
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Горизонтальная дальность видимости (км)"}
            >
              <LineChart
                id="chart9"
                loading={this.state.loading}
                data={this.state.chart9}
                dateAxis
                values={[
                  {
                    key: "WeatherRp5.visibility",
                    title: "Горизонтальная дальность видимости (км)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
            <ChartCard
              style={{ width: "47%", flex: "unset" }}
              title={"Высота снежного покрова (см)"}
            >
              <LineChart
                id="chart10"
                loading={this.state.loading}
                dateAxis
                data={this.state.chart10}
                values={[
                  {
                    key: "WeatherRp5.snow",
                    title: "Высота снежного покрова (см)",
                  },
                ]}
                category="WeatherRp5.dateTime"
                filled
                height="400px"
              />
            </ChartCard>
          </div>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
