import React, { Component } from "react";
import cubejs from "../../cube";
import moment from "moment";
import { groupBy } from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_amchartsdark from "@amcharts/amcharts4/themes/amchartsdark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { TabMainTitle } from "../../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { DatePickerR } from "../../components/newDesign/DatePicker/DatePickerR";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";

const cubejsApi = cubejs({ appId: 1 });

am4core.useTheme(am4themes_amchartsdark);
am4core.useTheme(am4themes_animated);

export default class Currency extends Component {
  state = {
    range: [
      moment().subtract(14, "days").startOf("day").utc(6),
      moment().utc(6),
    ],
    currencies: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { range } = this.state;
    cubejsApi
      .load({
        measures: ["ExchangeRates.value"],
        dimensions: ["ExchangeRates.date.day", "ExchangeRates.fullname"],
        filters: [
          {
            member: "ExchangeRates.date",
            operator: "inDateRange",
            values: range,
          },
        ],
      })
      .then((r) => {
        let data = r.rawData();
        data = data.map((el, index) => ({
          id: ++index,
          date: el["ExchangeRates.date.day"],
          name: el["ExchangeRates.fullname"],
          value: el["ExchangeRates.value"],
        }));
        data = data.filter(
          (e) =>
            e.name === "ДОЛЛАР США" ||
            e.name === "ЕВРО" ||
            e.name === "РОССИЙСКИЙ РУБЛЬ" ||
            e.name === "КИТАЙСКИЙ ЮАНЬ"
        );
        data = groupBy(data, "name");
        this.setState({ currencies: data }, this.unitChart);
      });
  };

  unitChart() {
    const { currencies } = this.state;
    let dates = currencies["ДОЛЛАР США"].map((item) => item.date);
    dates = dates.map((date) => ({ date }));
    dates.forEach((date, index) => {
      Object.keys(currencies).forEach((key) => {
        currencies[key]
          .map((item) => item.date)
          .forEach((cur) => {
            if (date.date === cur) {
              dates[index][key] = currencies[key].find(
                (item) => item.date === cur
              ).value;
            }
          });
      });
    });
    dates.forEach((item, index) => {
      dates[index].date = new Date(dates[index].date);
    });
    const chart = am4core.create("currency", am4charts.XYChart);
    this.chart = chart;
    chart.language.locale = am4lang_ru_RU;
    chart.data = dates;
    chart.colors.list = [
      am4core.color("#2776BD"),
      am4core.color("#00A1D0"),
      am4core.color("#7ED321"),
      am4core.color("#A8C600"),
      am4core.color("#C9B600"),
      am4core.color("#E3A600"),
      am4core.color("#F7941E"),
      am4core.color("#FC7149"),
      am4core.color("#8AD4EB"),
    ];
    chart.colors.step = 3;
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    this.createAxisAndSeries("ДОЛЛАР США", "Доллар", "circle");
    this.createAxisAndSeries("ЕВРО", "Евро", "triangle");
    this.createAxisAndSeries("РОССИЙСКИЙ РУБЛЬ", "Рубль", "rectangle");
    this.createAxisAndSeries("КИТАЙСКИЙ ЮАНЬ", "Юань", "none");
  }

  createAxisAndSeries(field, name, bulletType) {
    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;

    let interfaceColors = new am4core.InterfaceColorSet();

    switch (bulletType) {
      case "triangle":
        let bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 12;
        bullet.height = 12;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        let triangle = bullet.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor("background");
        triangle.strokeWidth = 0;
        triangle.direction = "top";
        triangle.width = 12;
        triangle.height = 12;
        break;
      case "rectangle":
        let bullet1 = series.bullets.push(new am4charts.Bullet());
        bullet1.width = 10;
        bullet1.height = 10;
        bullet1.horizontalCenter = "middle";
        bullet1.verticalCenter = "middle";

        let rectangle = bullet1.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor("background");
        rectangle.strokeWidth = 0;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      case "circle":
        let bullet2 = series.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.stroke = interfaceColors.getFor("background");
        bullet2.circle.strokeWidth = 0;
        break;
      default:
        break;
    }

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.grid.template.disabled = true;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { range } = this.state;
    return (
      <MainWrapper>
        <TabMainTitle>
          Курсы валют на период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <FilterWrapper>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range }, this.fetchData);
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title="Курсы валют">
            <div id="currency" style={{ height: "60vh" }}></div>
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
