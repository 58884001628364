import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Row } from "antd";
import Lists from "./Lists";
import cubejs from "../../cube";
import { DepartmentLists } from "../../constants";

const cubejsApi = cubejs({ appId: 1 });
export default class OperationalSummary extends Component {
  state = {
    filtersData: {},
    range: [moment().subtract(30, "d"), moment()],
  };

  render() {
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <h1
          level={4}
          style={{
            color: "#ffffff",
          }}
        >
          Информация за сегодня{" "}
          <span className="date-period">{moment().format("DD.MM.YYYY")}</span>
        </h1>

        <Row gutter={16} type="flex" style={{ margin: 0 }}>
          {DepartmentLists.map((item) => (
            <QueryRenderer
              query={{
                measures: [],
                timeDimensions: [
                  {
                    dimension: item["code"] + ".dateTime",
                    dateRange: "today",
                  },
                ],
                dimensions: item["dimension"],
                filters: [],
                order: item["order"],
                renewQuery: true,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                let code = item["code"];

                return resultSet ? (
                  <Lists
                    code={code}
                    item={item}
                    dataSource={resultSet ? resultSet.rawData() : []}
                  />
                ) : (
                  <p>Loading</p>
                );
              }}
            />
          ))}
        </Row>
      </div>
    );
  }
}
