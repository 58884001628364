import React, { Component } from "react";
import cubejs from "../cube";
import YandexMapWrap from "../charts/YandexMapWrap";
import Loader from "../components/Loader";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";

const cubejsApi = cubejs({ appId: 1 });

const NOT_CRITICAL = [
  "Давление",
  "Направление ветра",
  "Температура воздуха",
  "Скорость ветра",
  "Влажность",
  "Температура",
  "Атмосферное давление",
];

class Ecology extends Component {
  state = {
    mapData: [],
    dashboard: [],
    selectedStation: [],
    widgetData: [],
    stationsCount: null,
    filtersData: {
      "DStation.pnzNumber": {
        key: "DStation.pnzNumber",
        title: "Станция",
        options: [],
        values: [],
      },
      "DStation.dataType": {
        key: "DStation.dataType",
        title: "Источник данных",
        options: [],
        values: [],
      },
      "DStation.type": {
        key: "DStation.type",
        title: "Тип станции",
        options: [],
        values: [],
      },
      "DStation.locality": {
        key: "DStation.locality",
        single: true,
        title: "Город",
        options: [],
        values: [],
      },
      "SmartECOKazgidrometGaz.measureNameRu": {
        key: "SmartECOKazgidrometGaz.measureNameRu",
        single: true,
        title: "Элементы",
        options: [],
        values: [],
      },
    },
    filters: [],
    byElements: false,
    loading: true,
  };

  componentDidMount() {
    this.loadFilters();
    this.getData();
  }

  getData = () => {
    const { selectedStation, filters } = this.state;

    let requests = [
      {
        measures: ["SmartECOKazgidrometGaz.value"],
        dimensions: [
          "DStation.id",
          "DStation.x",
          "DStation.y",
          "DStation.type",
          "DStation.dataType",
          "DStation.locality",
          "DStation.address",
          "DStation.pnzNumber",
          "SmartECOKazgidrometGaz.measureNameRu",
          "SmartECOKazgidrometGaz.maxpokazatel",
          "SmartECOKazgidrometGaz.unittype",
        ],
        filters,
      },
    ];

    Promise.all(requests.map((e) => cubejsApi.load(e))).then((r) => {
      let data = r[0].rawData();
      let mapData = Array.from(new Set(data.map((el) => el["DStation.id"])));
      mapData = mapData.map((el) => ({
        id: el,
        x: data.find((e) => e["DStation.id"] === el)["DStation.x"],
        y: data.find((e) => e["DStation.id"] === el)["DStation.y"],
        color: this.state.byElements
          ? this.returnColor(
              this.returnPercentage(
                data.find((e) => e["DStation.id"] === el)[
                  "SmartECOKazgidrometGaz.value"
                ],
                data.find((e) => e["DStation.id"] === el)[
                  "SmartECOKazgidrometGaz.maxpokazatel"
                ]
              )
            )
          : this.returnMarkerColor(
              data.find((e) => e["DStation.id"] === el)["DStation.dataType"],
              data.find((e) => e["DStation.id"] === el)["DStation.type"]
            ),
        dataType: data.find((e) => e["DStation.id"] === el)[
          "DStation.dataType"
        ],
        type: data.find((e) => e["DStation.id"] === el)["DStation.type"],
        level: this.state.byElements
          ? this.returnLevel(
              this.returnPercentage(
                data.find((e) => e["DStation.id"] === el)[
                  "SmartECOKazgidrometGaz.value"
                ],
                data.find((e) => e["DStation.id"] === el)[
                  "SmartECOKazgidrometGaz.maxpokazatel"
                ]
              )
            )
          : "",
        ballon: {
          hintContent: data.find((e) => e["DStation.id"] === el)[
            "DStation.pnzNumber"
          ],
        },
      }));
      let dashboard = [
        ...new Set(
          data.map((e) => e["DStation.dataType"] + ", " + e["DStation.type"])
        ),
      ];
      dashboard = dashboard.map((el) => ({
        category: el,
        value: [
          ...new Set(
            data
              .filter(
                (e) => e["DStation.dataType"] + ", " + e["DStation.type"] === el
              )
              .map((e) => e["DStation.id"])
          ),
        ].length,
      }));
      let widgetData = [...new Set(data.map((e) => e["DStation.id"]))];
      if (selectedStation.length !== 0)
        widgetData = widgetData.filter((e) => selectedStation.includes(e));

      widgetData = widgetData.map((el) => ({
        title:
          data.find((e) => e["DStation.id"] === el)["DStation.pnzNumber"] +
          " " +
          data.find((e) => e["DStation.id"] === el)["DStation.address"] +
          " " +
          data.find((e) => e["DStation.id"] === el)["DStation.locality"],
        type: data.find((e) => e["DStation.id"] === el)["DStation.type"],
        measures: data.filter((e) => e["DStation.id"] === el),
      }));
      let stationsCount = [...new Set(data.map((e) => e["DStation.id"]))]
        .length;
      this.setState({
        mapData,
        dashboard,
        widgetData,
        stationsCount,
        loading: false,
      });
    });
  };

  async loadFilters() {
    let { filtersData, filters } = this.state;
    const filterNames = Object.keys(filtersData);

    let reqs = [];
    filterNames.forEach((f) => {
      let newFilters = filters.filter((e) => e.member !== f);
      reqs.push(
        cubejsApi.load(
          newFilters.length !== 0
            ? {
                dimensions: [f],
                filters: newFilters,
              }
            : {
                dimensions: [f],
              }
        )
      );
    });

    const responses = await Promise.all(reqs);
    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });

    filtersData["SmartECOKazgidrometGaz.measureNameRu"].options.splice(
      filtersData["SmartECOKazgidrometGaz.measureNameRu"].options.findIndex(
        (e) => e === "Температура"
      ),
      1
    );

    this.setState({ filtersData });
  }

  applyFilters = (filter, values, single) => {
    let { filtersData, byElements, filters } = this.state;
    let newByElements = byElements;
    let newFilters = filters;

    if (
      single === true &&
      filter === "SmartECOKazgidrometGaz.measureNameRu" &&
      values
    ) {
      newByElements = true;
    }
    if (
      single === true &&
      filter === "SmartECOKazgidrometGaz.measureNameRu" &&
      values === undefined
    ) {
      newByElements = false;
    }
    values = Array.isArray(values) ? values : [values];
    if (
      values.length !== 0 &&
      !values.includes(undefined) &&
      !newFilters.find((x) => x.member === filter)
    ) {
      newFilters.push({
        member: filter,
        operator: "equals",
        values: values,
      });
    } else if (
      newFilters.find((x) => x.member === filter) &&
      values.length !== 0 &&
      !values.includes(undefined)
    ) {
      newFilters[newFilters.findIndex((x) => x.member === filter)].values =
        values;
    } else {
      newFilters.splice(
        newFilters.findIndex((x) => x.member === filter),
        1
      );
    }
    this.setState(
      {
        selectedStation: [],
        filtersData: {
          ...filtersData,
          [filter]: {
            ...filtersData[filter],
            values,
          },
        },
        filters: newFilters,
        byElements: newByElements,
        loading: true,
      },
      () => {
        this.loadFilters();
        this.getData();
      }
    );
  };

  loadDetails = (items) => {
    let selectedStation = Array.isArray(items)
      ? items.map((el) => el.id)
      : [items];
    this.setState({ selectedStation }, this.getData);
  };

  returnMarkerColor(source, type) {
    let color;
    if ((source === "Казгидромет") & (type === "автоматика")) {
      color = "#B3DDCC";
    } else if ((source === "Казгидромет") & (type === "ручные")) {
      color = "#3D91BE";
    } else if ((source === "Смарт ЭКО") & (type === "автоматика")) {
      color = "#9c27b0";
    } else if ((source === "Смарт ЭКО") & (type === "ручные")) {
      color = "#F997BA";
    }
    return color;
  }

  returnColor(percentage) {
    let color;
    let perc = Number(percentage);
    switch (true) {
      case perc >= 0 && perc < 19:
        color = "#32CD66";
        break;
      case perc >= 19 && perc < 50:
        color = "#FFEB3B";
        break;
      case perc >= 50 && perc < 85:
        color = "#FF6600";
        break;
      case perc >= 85 && perc < 100:
        color = "#f44336";
        break;
      default:
        color = "#32CD66";
        break;
    }
    return color;
  }

  returnLevel(percentage) {
    let level;
    let perc = Number(percentage);
    switch (true) {
      case perc >= 0 && perc < 19:
        level = "Низкий уровень загрязнения";
        break;
      case perc >= 19 && perc < 50:
        level = "В пределах нормы";
        break;
      case perc >= 50 && perc < 85:
        level = "Высокий уровень загрязнения";
        break;
      case perc >= 85 && perc < 100:
        level = "Критический уровень загрязнения";
        break;
      default:
        level = "Низкий уровень загрязнения";
        break;
    }
    return level;
  }

  returnPercentage(value, maxValue) {
    let percentage = (value * 100) / maxValue;
    if (percentage > 97) {
      return 97;
    } else return percentage;
  }

  render() {
    const { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    return (
      <MainWrapper>
        <TabMainTitle>
          Всего газоанализаторов:{" "}
          <span className="date-period">{this.state.stationsCount}</span>
        </TabMainTitle>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode={filter.single ? "" : "multiple"}
                  onChange={(value) => {
                    this.applyFilters(filter.key, value, filter.single);
                  }}
                  allowClear
                  size="small"
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>

        {!this.state.loading ? (
          <RowWrapper>
            <div style={{ width: "30%" }}>
              <YandexMapWrap
                objects={this.state.mapData}
                idDataKey="id"
                showDefaultCity
                xDataKey="x"
                yDataKey="y"
                handleClick={this.loadDetails}
                onClusterClick={this.loadDetails}
                height="550px"
                zoom={5}
              />

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  flexWrap: "wrap",
                }}
              >
                {this.state.dashboard.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      marginRight: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: this.returnMarkerColor(
                          item.category.split(", ")[0],
                          item.category.split(", ")[1]
                        ),
                        height: "15px",
                        borderRadius: "10",
                        width: "15px",
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>
                      {item.category} ({item.value})
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                width: "69%",
                maxHeight: "75vh",
                overflowX: "auto",
                background: "#2F2F39",
                border: "1px solid #40404D",
                borderRadius: "4px",
                marginLeft: 10,
                padding: 24,
              }}
            >
              {this.state.widgetData.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingBottom: 30,
                    marginBottom: 20,
                    borderBottom: "1px solid #40404D",
                  }}
                >
                  {item.measures
                    .filter(
                      (e) =>
                        !NOT_CRITICAL.includes(
                          e["SmartECOKazgidrometGaz.measureNameRu"]
                        )
                    )
                    .map((measure) => {
                      let title =
                        measure["SmartECOKazgidrometGaz.measureNameRu"];
                      let maxValue = measure[
                        "SmartECOKazgidrometGaz.maxpokazatel"
                      ]
                        ? measure[
                            "SmartECOKazgidrometGaz.maxpokazatel"
                          ].toFixed(4)
                        : 0;
                      let value = measure["SmartECOKazgidrometGaz.value"];
                      value =
                        value.toString().length > 8 ? value.toFixed(8) : value;
                      let percentage = this.returnPercentage(value, maxValue);
                      let unittype = measure["SmartECOKazgidrometGaz.unittype"];
                      return (
                        <ChartCard
                          title={title}
                          style={{ width: "46%", flex: "unset" }}
                        >
                          {value !== 0 ? (
                            <RowWrapper>
                              <div
                                className="content-wrap"
                                style={{ marginLeft: 20 }}
                              >
                                <h2
                                  style={{
                                    color: `${this.returnColor(percentage)}`,
                                  }}
                                >
                                  {value} {unittype}
                                </h2>
                                <p
                                  style={{
                                    color: `${this.returnColor(percentage)}`,
                                  }}
                                >
                                  {this.returnLevel(percentage)}
                                </p>
                              </div>
                              <div
                                className="graph-vertival"
                                style={{
                                  position: "relative",
                                  marginLeft: "auto",
                                }}
                              >
                                <ul style={{ marginBottom: 0 }}>
                                  <li className="red" />
                                  <li className="orange" />
                                  <li className="orange" />
                                  <span
                                    style={{
                                      bottom: `${percentage}%`,
                                      fontSize: "20px",
                                      color: `${this.returnColor(percentage)}`,
                                    }}
                                  >
                                    &#8594;
                                  </span>
                                  <li className="yellow" />
                                  <li className="yellow" />
                                  <li className="green" />
                                </ul>
                              </div>
                            </RowWrapper>
                          ) : (
                            <span style={{ display: "block", padding: 20 }}>
                              Нет данных
                            </span>
                          )}
                        </ChartCard>
                      );
                    })}
                  <div
                    style={{ width: "46%", fontSize: 16, lineHeight: "36px" }}
                  >
                    {item.title + " "}(
                    <span
                      style={{
                        color:
                          item.type === "автоматика" ? "#32CD66" : "#ff6600",
                      }}
                    >
                      {item.type}
                    </span>
                    )
                    {item.measures
                      .filter((e) =>
                        NOT_CRITICAL.includes(
                          e["SmartECOKazgidrometGaz.measureNameRu"]
                        )
                      )
                      .map((measure) => {
                        let title =
                          measure["SmartECOKazgidrometGaz.measureNameRu"];
                        let value = measure["SmartECOKazgidrometGaz.value"]
                          ? measure["SmartECOKazgidrometGaz.value"]
                          : 0;
                        if (
                          title === "Скорость ветра" ||
                          title === "Температура"
                        )
                          value = value.toFixed(1);
                        else value = value.toFixed(0);
                        let unittype =
                          measure["SmartECOKazgidrometGaz.unittype"];
                        if (title !== "Температура") {
                          return (
                            <div>
                              {title}: {value} {unittype}
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              ))}
            </div>
          </RowWrapper>
        ) : (
          <Loader />
        )}
      </MainWrapper>
    );
  }
}

export default Ecology;
