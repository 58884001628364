import React, { Component } from 'react';
import moment from 'moment';
import { QueryRenderer } from '@cubejs-client/react';
import { Row, Col, DatePicker, Select, Table } from 'antd';

import cubejs from '../cube';

import ExportExcel from '../components/ExportExcel';

import {
  SingleNumber,
  PieChart,
  HorizontalBarChart as HorizonBarChart
} from '../components/visualizations';
import {
  getSingleValue,
  generateBarData,
  generatePieData
} from '../utils/visualizations';
import { formatDate } from '../utils/helpers';

const { Option } = Select;

const cubejsApi = cubejs({ appId: 1 });

const detailQuery = {
  dimensions: [
    'Appeals.appealNum',
    'Appeals.appealType',
    'Appeals.expired',
    // 'Appeals.signedECP',
    'Appeals.answerType',
    // 'Appeals.region',
    'Appeals.executor',
    'Appeals.category',
    'Appeals.subCategory',
    // 'Appeals.sendingDate',
    'Appeals.submissionDate',
    'Appeals.replyDate'
  ]
};

const itemColumns = [
  { title: 'Рег. номер обращения', dataIndex: 'Appeals.appealNum' },
  { title: 'Тип', dataIndex: 'Appeals.appealType' },
  { title: 'Истек', dataIndex: 'Appeals.expired' },
  // { title: 'Подписан ЭЦП', dataIndex: 'Appeals.signedECP' },
  { title: 'Способ ответа', dataIndex: 'Appeals.answerType' },
  // { title: 'Район', dataIndex: 'Appeals.region' },
  { title: 'Исполнитель', dataIndex: 'Appeals.executor' },
  { title: 'Категория', dataIndex: 'Appeals.category' },
  { title: 'Подкатегория', dataIndex: 'Appeals.subCategory' },
  { title: 'Дата отправки', dataIndex: 'Appeals.sendingDate' },
  { title: 'Дата подачи', dataIndex: 'Appeals.submissionDate' },
  { title: 'Дата закрытия обращения', dataIndex: 'Appeals.replyDate' }
];

const renderDetail = items => (
  <Table dataSource={items} pagination={false} rowKey="id" size="small">
    <Table.Column title="Рег. номер обращения" dataIndex="Appeals.appealNum" />
    <Table.Column title="Тип" dataIndex="Appeals.appealType" />
    <Table.Column title="Истек" dataIndex="Appeals.expired" />
    {/* <Table.Column title="Подписан ЭЦП" dataIndex="Appeals.signedECP" /> */}
    <Table.Column title="Способ ответа" dataIndex="Appeals.answerType" />
    {/* <Table.Column title="Район" dataIndex="Appeals.region" /> */}
    <Table.Column title="Исполнитель" dataIndex="Appeals.executor" />
    <Table.Column title="Категория" dataIndex="Appeals.category" />
    <Table.Column title="Подкатегория" dataIndex="Appeals.subCategory" />
    {/* <Table.Column
      title="Дата отправки"
      dataIndex="Appeals.sendingDate"
      render={d => formatDate(d)}
    /> */}
    <Table.Column
      title="Дата подачи"
      dataIndex="Appeals.submissionDate"
      render={d => formatDate(d)}
    />
    <Table.Column
      title="Дата закрытия обращения"
      dataIndex="Appeals.replyDate"
      render={d => formatDate(d)}
    />
  </Table>
);

export default class Appeals extends Component {
  state = {
    filtersData: {
      'Appeals.appealType': {
        key: 'Appeals.appealType',
        title: 'Тип обращения',
        options: [],
        values: []
      },
      'Appeals.theStatus': {
        key: 'Appeals.theStatus',
        title: 'Статус обращения',
        options: [],
        values: []
      },
      'Appeals.answerType': {
        key: 'Appeals.answerType',
        title: 'Способ ответа',
        options: [],
        values: []
      },
      // 'Appeals.region': {
      //   key: 'Appeals.region',
      //   title: 'Регион',
      //   options: [],
      //   values: []
      // },
      'Appeals.executor': {
        key: 'Appeals.executor',
        title: 'Исполнитель',
        options: [],
        values: []
      },
      'Appeals.category': {
        key: 'Appeals.category',
        title: 'Категория обращения',
        options: [],
        values: []
      }
    },
    range: [moment().subtract(100, 'd'), moment()]
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map(f => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map(d => d[filterNames[i]])
        }
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values
        }
      }
    });
  };

  render() {
    const { filtersData, range } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: 'Appeals.submissionDate',
        operator: 'inDateRange',
        values: range
      }
    ];
    filterNames.forEach(f => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: 'equals',
            values: filter.values.map(f => f.toString())
          }
        ];
      }
    });

    return (
      <div
        style={{
          padding: 10
        }}
      >
        <h1 className="page-title">
          Обращения граждан данные с {' '}
          <span style={{ color: '#ff9900' }}>{range[0].format('DD.MM.YYYY')} <span style={{ color: '#fff' }}>по {' '}</span>
            {range[1].format('DD.MM.YYYY')}</span>
        </h1>

        <Row gutter={20}>
          <Col span={3}>
            <QueryRenderer
              query={{
                measures: ['Appeals.appealsNum'],
                filters
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div style={{ marginBottom: 20 }}>
                    <SingleNumber
                      number={getSingleValue(resultSet, 'Appeals.appealsNum')}
                      title="Общее количество обращений"
                    />
                  </div>
                );
              }}
            />
          </Col>
          <Col span={3}>
            {/* <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"],
                filters: [
                  ...filters,
                  {
                    member: "Appeals.signedECP",
                    operator: "equals",
                    values: ["Подписано ЭЦП"]
                  }
                ]
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div style={{ marginBottom: 20 }}>
                    <SingleNumber
                      number={getSingleValue(resultSet, "Appeals.appealsNum")}
                      title="Подписаны ЭЦП"
                    />
                  </div>
                );
              }}
            /> */}
          </Col>

          <Col span={14}>
            <Row gutter={10}>
              {filterNames.map((f, i) => {
                const filter = filtersData[f];
                return (
                  <Col span={8} key={i}>
                    <h4>{filter.title}</h4>
                    <Select
                      mode="multiple"
                      placeholder={filter.title}
                      onChange={value => this.applyFilters(filter.key, value)}
                      size="small"
                      allowClear
                      style={{ width: '100%', marginBottom: 6 }}
                    >
                      {filter.options.map(val => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                );
              })}
              <Col span={8}>
                <h4>Период</h4>
                <DatePicker.RangePicker
                  onChange={range => {
                    this.setState({ range });
                  }}
                  value={range}
                  showTime={{
                    secondStep: 60,
                    minuteStep: 15
                  }}
                  size="small"
                  style={{ marginBottom: 6 }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <QueryRenderer
              query={{
                dimensions: [
                  'Appeals.appealType',
                  'Appeals.expired',
                  // 'Appeals.signedECP',
                  'Appeals.answerType',
                  // 'Appeals.region',
                  'Appeals.executor',
                  'Appeals.category',
                  'Appeals.subCategory',
                  'Appeals.sendingDate',
                  'Appeals.submissionDate'
                ],
                filters
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                return (
                  <ExportExcel
                    filename="Обращения"
                    data={resultSet.rawData()}
                    fields={itemColumns}
                  />
                );
              }}
            />
          </Col>
        </Row>

        <Row gutter={20} style={{ marginBottom: 20 }}>
          <Col span={6}>
            <div className="card">
              <div className="title">Тип обращения</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.appealType'],
                  filters
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <PieChart
                      loading={!resultSet}
                      id="AppealsTypePie"
                      {...generatePieData(resultSet)}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="card">
              <div className="title">Статус обращения</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.theStatus'],
                  filters
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <PieChart
                      loading={!resultSet}
                      id="AppealsStatusPie"
                      {...generatePieData(resultSet)}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="card">
              <div className="title">Способ подачи обращения</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.source'],
                  filters: [
                    ...filters,
                    {
                      member: 'Appeals.source',
                      operator: 'notEquals',
                      values: ['По талону']
                    }
                  ]
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <PieChart
                      loading={!resultSet}
                      id="AppealsSourcePie"
                      {...generatePieData(resultSet)}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="card">
              <div className="title">Способ ответа</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.answerType'],
                  filters
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <PieChart
                      loading={!resultSet}
                      id="AppealsAnswerTypePie"
                      {...generatePieData(resultSet)}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={20} style={{ marginBottom: 20 }}>
          {/* <Col span={12}>
            <div className="card">
              <div className="title">Регионы</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.expired', 'Appeals.region'],
                  filters
                  // order: {
                  //   'Appeals.appealsNum': 'asc'
                  // }
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <HorizonBarChart
                      loading={!resultSet}
                      id="HorizonBarRegionChart"
                      {...generateBarData(resultSet)}
                      showValues
                      wrapLabels
                      labelMaxWidth={300}
                      height="400px"
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                      // scrollStart = {0.5}
                      // scrollEnd = {1}
                    />
                  );
                }}
              />
            </div>
          </Col> */}
          <Col span={8}>
            <div className="card">
              <div className="title">Исполнитель</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.expired', 'Appeals.executor'],
                  filters
                  // order: {
                  //   'Appeals.appealsNum': 'asc'
                  // }
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <HorizonBarChart
                      loading={!resultSet}
                      id="HorizonBarExecutorChart"
                      {...generateBarData(resultSet)}
                      labelFontSize={10}
                      wrapLabels
                      showValues
                      labelMaxWidth={240}
                      height="400px"
                      scrollStart={0.7}
                      scrollEnd={1}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
          {/* </Row>

        <Row gutter={20} style={{ marginBottom: 20 }}> */}
          <Col span={8}>
            <div className="card">
              <div className="title">Категория обращений</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.expired', 'Appeals.category'],
                  filters
                  // order: {
                  //   'Appeals.appealsNum': 'asc'
                  // }
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <HorizonBarChart
                      loading={!resultSet}
                      id="HorizonBarCategoryChart"
                      {...generateBarData(resultSet)}
                      labelFontSize={10}
                      wrapLabels
                      showValues
                      labelMaxWidth={240}
                      height="400px"
                      scrollStart={0.7}
                      scrollEnd={1}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>

          <Col span={8}>
            <div className="card">
              <div className="title">Подкатегория обращений</div>
              <QueryRenderer
                query={{
                  measures: ['Appeals.appealsNum'],
                  dimensions: ['Appeals.expired', 'Appeals.subCategory'],
                  filters
                  // order: {
                  //   'Appeals.appealsNum': 'asc'
                  // }
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <HorizonBarChart
                      loading={!resultSet}
                      id="HorizonBarSubCategoryChart"
                      {...generateBarData(resultSet)}
                      labelFontSize={10}
                      wrapLabels
                      showValues
                      labelMaxWidth={240}
                      height="400px"
                      scrollStart={0.84}
                      scrollEnd={1}
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
