import React from "react";

const Loader = ({ imgSize, fontSize }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <img
      alt="Загрузка"
      src="/images/spinner.gif"
      width={imgSize ? imgSize : "24px"}
      height={imgSize ? imgSize : "24px"}
      style={{ marginBottom: 4 }}
    />
    <div
      style={{
        fontSize: fontSize ? fontSize : 16,
        lineHeight: 1,
        color: "#fff",
      }}
    >
      Loading data...
    </div>
  </div>
);

export default Loader;
