/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Row,
  Col,
  Select,
  Table,
  DatePicker,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import { QueryRenderer } from "@cubejs-client/react";
import cubejs from "../cube";
import ExportExcel from "../components/ExportExcel";
import moment from "moment";
import Loader from "../components/Loader";

const cubejsApi = cubejs({ appId: 1 });

export default class AppealsMonitoring extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    records: [],
    visible: false,
    title: null,
    titleCount: null,
    modalLoading: false,
    modalFilter: [],
    currentPage: 1,
    pageSize: 10,
    filtersData: {
      "Appeals.region": {
        key: "Appeals.region",
        title: "Регион",
        options: [],
        values: [],
      },
      "Appeals.district": {
        key: "Appeals.district",
        title: "Населенный пункт",
        options: [],
        values: [],
      },
    },
  };

  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  calculateReplyDays = (replyDate, plannedDate, expired) => {
    if (expired === "Просрочено") {
      if (replyDate === null) {
        return moment().diff(moment(plannedDate), "days");
      } else {
        return moment(replyDate).diff(moment(plannedDate), "days");
      }
    } else if (expired === "Не просрочено") {
      return 0;
    }
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  renderRowColor(text, record) {
    return {
      props: {
        style: { color: record.color },
      },
      children: <div>{text}</div>,
    };
  }

  renderInProcess(text, record, index) {
    return {
      children: (
        <span>
          <a onClick={() => console.log("На исполнении")} className={index}>
            {text}
          </a>
        </span>
      ),
    };
  }

  renderRowColorAndWordBreak(text, record, maxWidth) {
    return {
      props: {
        style: { color: record.color },
      },
      children: (
        <Tooltip title={text}>
          <div
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: maxWidth,
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    };
  }

  handleColumn = (column, record, filters) => {
    this.setState({ modalLoading: true });
    let modalFilter = [];
    let segments = [];
    if (column === "На исполнении") {
      modalFilter = [
        {
          dimension: "Appeals.status",
          operator: "equals",
          values: ["Зарегистрировано"],
        },
      ];
    } else if (column === "Завершено") {
      modalFilter = [
        {
          dimension: "Appeals.status",
          operator: "equals",
          values: ["Завершено"],
        },
      ];
    }
    // else if (column === "Просрочено") {
    //   modalFilter = [
    //     {
    //       dimension: "Appeals.expired",
    //       operator: "equals",
    //       values: ["Просрочено"]
    //     }
    //   ];
    // }
    else if (column === "Общее количество обращений") {
      modalFilter = [];
    } else if (column === "Исполнено с нарушением срока") {
      segments = ["Appeals.outOfTime"];
    } else if (column === "Просрочено") {
      segments = ["Appeals.expiredForOperator"];
    }
    const { range } = this.state;
    cubejsApi
      .load({
        dimensions: [
          "Appeals.appealNum",
          "Appeals.source",
          "Appeals.appealType",
          "Appeals.category",
          "Appeals.subCategory",
          "Appeals.appealText",
          "Appeals.submissionDate",
          "Appeals.replyDate",
          "Appeals.plannedDate",
          "Appeals.expired",
          "Appeals.theStatus",
        ],
        filters: [
          {
            dimension: "Appeals.category",
            operator:
              record["Appeals.category"] === "ИТОГО" ? "notEquals" : "equals",
            values: [record["Appeals.category"]],
          },
          {
            member: "Appeals.submissionDate",
            operator: "inDateRange",
            values: range,
          },
          ...modalFilter,
          ...filters,
        ],
        segments,
        renewQuery: true,
      })
      .then((r) => {
        let records = r.rawData().map((element, index) => ({
          ...element,
          key: index + 1,
          "Appeals.replyDays": this.calculateReplyDays(
            element["Appeals.replyDate"],
            element["Appeals.plannedDate"],
            element["Appeals.expired"]
          ),
          "Appeals.submissionDate":
            element["Appeals.submissionDate"] != null
              ? moment(element["Appeals.submissionDate"]).format("YYYY-MM-DD")
              : "",
          "Appeals.submissionTime":
            element["Appeals.submissionDate"] != null
              ? moment(element["Appeals.submissionDate"]).format("HH:mm")
              : "",
          "Appeals.replyDate":
            element["Appeals.replyDate"] != null
              ? moment(element["Appeals.replyDate"]).format("YYYY-MM-DD")
              : "",
          "Appeals.plannedDate":
            element["Appeals.plannedDate"] != null
              ? moment(element["Appeals.plannedDate"]).format("YYYY-MM-DD")
              : "",
          color: element["Appeals.expired"] === "Просрочено" ? "#ff4a4a" : "",
        }));
        this.setState({
          modalLoading: false,
          records,
          visible: true,
          title: record["Appeals.category"],
          titleCount: records.length,
        });
      });
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res
            .rawData()
            .map((d) => d[filterNames[i]])
            .filter((e) => e),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  // Pagination
  onChange = (page) => this.setState({ ...this.state, currentPage: page });

  onShowSizeChange = (current, pageSize) =>
    this.setState({ ...this.state, pageSize, currentPage: 1 });

  sortHandler = (a, b, key, compare = false) => {
    if (a["Appeals.category"] !== "ИТОГО") {
      if (compare) return a[key].localeCompare(b[key]);
      else return a[key] - b[key];
    } else return false;
  };

  render() {
    const { range, visible, records, title, titleCount, filtersData } =
      this.state;
    const filterNames = Object.keys(filtersData);
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <div style={{ padding: 10 }} className="appeals-monitoring">
        {this.state.modalLoading && (
          <div className="loading-wrap">
            <Loader />
          </div>
        )}
        <Row type="flex" style={{ alignItems: "center" }} align="top">
          <Col span={8} style={{ display: "flex" }}>
            <h2
              style={{
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "bold",
                paddingRight: 10,
                textTransform: "uppercase",
              }}
            >
              ЗА ПЕРИОД
            </h2>
            <Col span={9}>
              <DatePicker.RangePicker
                onChange={this.handleRange}
                separator="—"
                value={range}
                showTime={{
                  secondStep: 60,
                  minuteStep: 15,
                }}
                allowClear={false}
                size="small"
                style={{ marginRight: "auto", marginBottom: "5px" }}
              />
            </Col>
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            {filterNames.map((f, i) => {
              const filter = filtersData[f];
              return (
                <Col span={6}>
                  <Select
                    mode="multiple"
                    placeholder={`${filter.title}`}
                    onChange={(value) => this.applyFilters(filter.key, value)}
                    size="small"
                    allowClear
                    style={{ width: "97%" }}
                  >
                    {filter.options.map((val) => (
                      <Select.Option value={val} key={val}>
                        {val}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <QueryRenderer
              query={{
                dimensions: ["Appeals.category"],
                measures: [
                  "Appeals.appealsNum",
                  "Appeals.finishedAppeals",
                  "Appeals.expiredAppeals",
                  "Appeals.registredAppeals",
                  "Appeals.efficiencyCoef",
                  "Appeals.expiredForOperatorAppeals",
                  "Appeals.outOfTimeForOperator",
                ],
                filters,
                renewQuery: true,
                // limit: pageSize
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Spin />;
                let data = resultSet.rawData();
                data = data.map((el, id) => {
                  return { ...el, key: ++id };
                });
                data.push({
                  "Appeals.appealsNum": data.reduce(
                    (a, b) => a + +b["Appeals.appealsNum"],
                    0
                  ),
                  "Appeals.category": "ИТОГО",
                  "Appeals.efficiencyCoef": parseInt(
                    data.reduce((a, b) => a + +b["Appeals.efficiencyCoef"], 0) /
                      data.length
                  ),
                  "Appeals.expiredAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.expiredAppeals"],
                    0
                  ),
                  "Appeals.expiredForOperatorAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.expiredForOperatorAppeals"],
                    0
                  ),
                  "Appeals.finishedAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.finishedAppeals"],
                    0
                  ),
                  "Appeals.outOfTimeForOperator": data.reduce(
                    (a, b) => a + +b["Appeals.outOfTimeForOperator"],
                    0
                  ),
                  "Appeals.registredAppeals": data.reduce(
                    (a, b) => a + +b["Appeals.registredAppeals"],
                    0
                  ),
                });
                return (
                  <div>
                    <Row type="flex" justify="space-between">
                      <Col>
                        <h3>
                          Общее количество обращений :{" "}
                          {resultSet
                            .rawData()
                            .reduce((a, b) => a + +b["Appeals.appealsNum"], 0)}
                        </h3>
                      </Col>
                      <Col>
                        <ExportExcel
                          filename={`Мониторинг исполнения (${
                            filtersData["Appeals.region"].values.length !== 0
                              ? filtersData["Appeals.region"].values
                              : "Карагандинская область"
                          }, ${
                            filtersData["Appeals.district"].values.length !== 0
                              ? filtersData["Appeals.district"].values
                              : ""
                          }) за ${moment(range[0]).format(
                            "DD.MM.YY HH:mm:ss"
                          )} – ${moment(range[1]).format("DD.MM.YY HH:mm:ss")}`}
                          data={data}
                          fields={[
                            {
                              title: "№",
                              dataIndex: "key",
                            },
                            {
                              title: "Категория",
                              dataIndex: "Appeals.category",
                            },
                            {
                              title: "Общее количество обращений",
                              dataIndex: "Appeals.appealsNum",
                            },
                            {
                              title: "На исполнении",
                              dataIndex: "Appeals.registredAppeals",
                            },
                            {
                              title: "Просрочено",
                              dataIndex: "Appeals.expiredForOperatorAppeals",
                            },
                            {
                              title: "Завершено",
                              dataIndex: "Appeals.finishedAppeals",
                            },
                            {
                              title: "Исполнено с нарушением срока",
                              dataIndex: "Appeals.outOfTimeForOperator",
                            },
                            {
                              title: "Коэффициент эффективности %",
                              dataIndex: "Appeals.efficiencyCoef",
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    <Table
                      scroll={{ y: "70vh" }}
                      loading={!resultSet}
                      dataSource={data.filter((el) => el["Appeals.category"])}
                      pagination={{
                        defaultPageSize: 100,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                      }}
                      size="small"
                    >
                      <Table.Column
                        title="№"
                        dataIndex="key"
                        align="center"
                        width={40}
                        sorter={(a, b) => this.sortHandler(a, b, "key")}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Категория"
                        dataIndex="Appeals.category"
                        align="left"
                        width={500}
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.category", true)
                        }
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Общее количество обращений"
                        dataIndex="Appeals.appealsNum"
                        align="center"
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.appealsNum")
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn(
                                    "Общее количество обращений",
                                    record,
                                    filters
                                  )
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            ),
                          };
                        }}
                      />
                      <Table.Column
                        title="На исполнении"
                        align="center"
                        dataIndex="Appeals.registredAppeals"
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.registredAppeals")
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn(
                                    "На исполнении",
                                    record,
                                    filters
                                  )
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            ),
                          };
                        }}
                      />
                      <Table.Column
                        title="Просрочено"
                        align="center"
                        dataIndex="Appeals.expiredForOperatorAppeals"
                        sorter={(a, b) =>
                          this.sortHandler(
                            a,
                            b,
                            "Appeals.expiredForOperatorAppeals"
                          )
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn(
                                    "Просрочено",
                                    record,
                                    filters
                                  )
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            ),
                          };
                        }}
                      />
                      <Table.Column
                        align="center"
                        title="Завершено"
                        dataIndex="Appeals.finishedAppeals"
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.finishedAppeals")
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn(
                                    "Завершено",
                                    record,
                                    filters
                                  )
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            ),
                          };
                        }}
                      />
                      <Table.Column
                        title="Исполнено с нарушением срока"
                        align="center"
                        dataIndex="Appeals.outOfTimeForOperator"
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.outOfTimeForOperator")
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn(
                                    "Исполнено с нарушением срока",
                                    record,
                                    filters
                                  )
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            ),
                          };
                        }}
                      />
                      {/* <Table.Column
                        align="center"
                        title="Просрочено"
                        dataIndex="Appeals.expiredAppeals"
                        sorter={(a, b) =>
                          a["Appeals.expiredAppeals"] -
                          b["Appeals.expiredAppeals"]
                        }
                        render={(text, record, index) => {
                          return {
                            children: (
                              <a
                                style={{ fontSize: "14px", padding: "10px" }}
                                onClick={() =>
                                  this.handleColumn("Просрочено", record)
                                }
                                className={index}
                              >
                                {text}
                              </a>
                            )
                          };
                        }}
                      /> */}
                      <Table.Column
                        title="Коэффициент эффективности %"
                        align="center"
                        dataIndex="Appeals.efficiencyCoef"
                        sorter={(a, b) =>
                          this.sortHandler(a, b, "Appeals.efficiencyCoef")
                        }
                      />
                    </Table>
                    {/* 
                    <Pagination
                      pageSizeOptions={[
                        "10",
                        "20",
                        "30",
                        "40",
                        "50",
                        "60",
                        "70",
                        "80",
                        "90",
                        "100"
                      ]}
                      current={currentPage}
                      pageSize={pageSize}
                      total={data && data.length}
                      showSizeChanger
                      onShowSizeChange={this.onShowSizeChange}
                      onChange={this.onChange}
                    /> */}
                  </div>
                );
              }}
            />
          </Col>
        </Row>
        <Modal
          title={
            <Row
              style={{ paddingRight: 20 }}
              type="flex"
              justify="space-between"
            >
              <div>
                <h5>{`Количество обращений по категории ${title}: ${titleCount} (с ${moment(
                  range[0]
                ).format("DD/MM/YY")} по ${moment(range[1]).format(
                  "DD/MM/YY"
                )})`}</h5>
              </div>
              <ExportExcel
                filename={`Количество обращений по категории ${title}: ${titleCount} (с ${moment(
                  range[0]
                ).format("DD/MM/YY")} по ${moment(range[1]).format(
                  "DD/MM/YY"
                )})`}
                data={records}
                fields={[
                  {
                    title: "№",
                    dataIndex: "key",
                  },
                  {
                    title: "Номер в системе",
                    dataIndex: "Appeals.appealNum",
                  },
                  {
                    title: "Тип обращения",
                    dataIndex: "Appeals.appealType",
                  },
                  {
                    title: "Подкатегория обращения",
                    dataIndex: "Appeals.subCategory",
                  },
                  {
                    title: "Текст обращения",
                    dataIndex: "Appeals.appealText",
                  },
                  {
                    title: "Дата подачи",
                    dataIndex: "Appeals.submissionDate",
                  },
                  {
                    title: "Время подачи",
                    dataIndex: "Appeals.submissionTime",
                  },
                  {
                    title: "Дата закрытия (регламент)",
                    dataIndex: "Appeals.replyDateReglament",
                  },
                  {
                    title: "Дата закрытия (фактическая)",
                    dataIndex: "Appeals.replyDate",
                  },
                  {
                    title: "Статус",
                    dataIndex: "Appeals.theStatus",
                  },
                  {
                    title: "Количество дней просрочки",
                    dataIndex: "Appeals.replyDays",
                  },
                ]}
              />
            </Row>
          }
          visible={visible}
          onCancel={this.hideModal}
          width="96%"
          footer={null}
          bodyStyle={{
            // height: "80vh",
            paddingTop: 0,
          }}
          centered
        >
          <Table
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: 50,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a.key - b.key;
                },
              },
              {
                title: "Номер в системе",
                dataIndex: "Appeals.appealNum",
                key: "appealNum",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.appealNum"].localeCompare(
                    b["Appeals.appealNum"]
                  );
                },
              },
              {
                title: "Источник",
                dataIndex: "Appeals.source",
                key: "source",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.source"].localeCompare(b["Appeals.source"]);
                },
              },
              {
                title: "Тип обращения",
                dataIndex: "Appeals.appealType",
                key: "appealType",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.appealType"].localeCompare(
                    b["Appeals.appealType"]
                  );
                },
              },
              {
                title: "Категория",
                dataIndex: "Appeals.category",
                key: "category",
                width: 150,
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "15ch"),
                sorter: (a, b) => {
                  return a["Appeals.category"].localeCompare(
                    b["Appeals.category"]
                  );
                },
              },
              {
                title: "Подкатегория обращения",
                dataIndex: "Appeals.subCategory",
                key: "subCategory",
                width: 150,
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "15ch"),
                sorter: (a, b) => {
                  return a["Appeals.subCategory"].localeCompare(
                    b["Appeals.subCategory"]
                  );
                },
              },
              {
                title: "Текст обращения",
                dataIndex: "Appeals.appealText",
                key: "appealText",
                width: 300,
                textWrap: "word-break",
                render: (text, record) =>
                  this.renderRowColorAndWordBreak(text, record, "35ch"),
                sorter: (a, b) => {
                  return a["Appeals.appealText"].localeCompare(
                    b["Appeals.appealText"]
                  );
                },
              },
              {
                title: "Дата подачи",
                dataIndex: "Appeals.submissionDate",
                key: "submissionDate",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.submissionDate"]) -
                    new Date(b["Appeals.submissionDate"])
                  );
                },
              },
              {
                title: "Время подачи",
                dataIndex: "Appeals.submissionTime",
                key: "submissionTime",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.submissionTime"].localeCompare(
                    b["Appeals.submissionTime"]
                  );
                },
              },
              {
                title: "Планируемая дата закрытия",
                dataIndex: "Appeals.plannedDate",
                key: "plannedDate",
                width: 175,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.plannedDate"]) -
                    new Date(b["Appeals.plannedDate"])
                  );
                },
              },
              {
                title: "Дата закрытия (фактическая)",
                dataIndex: "Appeals.replyDate",
                key: "replyDate",
                width: 175,
                render: (d) =>
                  d ? moment(d).format("DD.MM.YYYY") : "В обработке",

                // render: this.renderRowColor,
                sorter: (a, b) => {
                  return (
                    new Date(a["Appeals.replyDate"]) -
                    new Date(b["Appeals.replyDate"])
                  );
                },
              },
              {
                title: "Статус обращения",
                dataIndex: "Appeals.theStatus",
                key: "theStatus",
                width: 150,
                render: this.renderRowColor,
                sorter: (a, b) => {
                  return a["Appeals.theStatus"].localeCompare(
                    b["Appeals.theStatus"]
                  );
                },
              },
              {
                title: "Количество дней просрочки",
                dataIndex: "Appeals.replyDays",
                key: "replyDays",
                width: 100,
                sorter: (a, b) =>
                  a["Appeals.replyDays"] - b["Appeals.replyDays"],
                render: this.renderRowColor,
              },
            ]}
            size="small"
            scroll={{ y: "60vh", x: "max-content" }}
            pagination={{
              defaultPageSize: 100,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            dataSource={records}
          />
        </Modal>
      </div>
    );
  }
}
