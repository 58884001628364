import React, { Component } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { Row, Col, Select } from 'antd';

import cubejs from '../../../cube';
import styles from "../Weather/weather.module.scss";

const cubejsApi = cubejs({ appId: 1 });

const CRITICAL = [
  'Оксид углерода (CO)',
  'Оксид серы (SO)',
  'Диоксид азота (NO₂)',
  'Сероводород (H₂S)',
  'Оксид азота, мг/м',
  'Диоксид азота (NO₂)',
  'Оксид серы (SO₂)',
  'Скорость ветра, м/с'
];

const kazgidroFilter = {
  dimension: 'SmartECOKazgidrometGaz.dataType',
  operator: 'equals',
  values: ['Казгидромет']
};

export default class Kazgidromet extends Component {
  state = {
    filtersData: {
      'DStation.locality': {
        key: 'DStation.locality',
        title: 'Город',
        options: [],
        values: ['Караганда'],
        defaultValue: 'Караганда'
      },
      'SmartECOKazgidrometGaz.postMn': {
        key: 'SmartECOKazgidrometGaz.postMn',
        title: 'Станция',
        options: [],
        values: ['ПНЗ №3'],
        defaultValue: 'ПНЗ №3',
        filters: [kazgidroFilter]
      }
    },
    stationId: 10
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map(f =>
      cubejsApi.load({ dimensions: [f], filters: filtersData[f].filters })
    );
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map(d => d[filterNames[i]])
        }
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values: Array.isArray(values) ? values : [values]
        }
      }
    });
  };

  loadDetails = async items => {
    const ids = Array.isArray(items)
      ? items.map(item => item['DStation.id'].toString())
      : [items.toString()];
    const resultSet = await cubejsApi.load({
      dimensions: [
        'DStation.id',
        'DStation.locality',
        'DStation.address',
        'DStation.pnzNumber',
        'DStation.type',
        'DStation.x',
        'DStation.y'
      ],
      filters: [
        {
          dimension: 'DStation.id',
          operator: 'equals',
          values: ids
        }
      ]
    });
    const arr = resultSet.rawData();
    if (arr.length) {
      this.setState({ stations: arr });
    }
  };

  returnLevel(percentage) {
    let level;
    let perc = Number(percentage);
    switch (true) {
      case (perc >= 0 && perc < 19):
        level = "Низкий уровень загрязнения"
        break
      case (perc >= 19 && perc < 50):
        level = "В пределах нормы"
        break
      case (perc >= 50 && perc < 85):
        level = "Высокий уровень загрязнения"
        break
      case (perc >= 85 && perc < 100):
        level = "Критический уровень загрязнения"
        break
      default:
        level = ""
        break;
    }
    return level;
  }

  returnColor(percentage) {
    let color;
    let perc = Number(percentage);
    switch (true) {
      case (perc >= 0 && perc < 19):
        color = "#32CD66"
        break
      case (perc >= 19 && perc < 50):
        color = "#FFEB3B"
        break
      case (perc >= 50 && perc < 85):
        color = "#FF6600"
        break
      case (perc >= 85 && perc < 100):
        color = "#f44336"
        break
      default:
        color = "#000"
        break;
    }
    return color;
  }

  returnPercentage(value, maxValue) {
    let percentage = ((value / maxValue) * 100) + 5;
    if (percentage > 95) {
      return 95;
    }
    else return percentage;
  }

  render() {
    const { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach(f => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: 'equals',
            values: filter.values.map(f => f.toString())
          }
        ];
      }
    });
    return (
      <div>
        <Row gutter={10} style={{ marginBottom: 10 }}>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <Col span={12} key={i}>
                <div className={styles.filter_label}>{filter.title}</div>
                <Select
                  placeholder={filter.title}
                  onChange={value => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  style={{ width: '100%' }}
                  defaultValue={filter.defaultValue}
                  className={styles.filter_select}
                >
                  {filter.options.map(val => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            );
          })}
        </Row>

        <QueryRenderer
          query={{
            measures: ['SmartECOKazgidrometGaz.value'],
            dimensions: [
              "DStation.id",
              "DStation.x",
              "DStation.y",
              "DStation.type",
              "DStation.dataType",
              "DStation.locality",
              "DStation.address",
              "DStation.pnzNumber",
              'SmartECOKazgidrometGaz.measureNameRu',
              'SmartECOKazgidrometGaz.maxpokazatel',
              //'SmartECOKazgidrometGaz.plevel',
              'SmartECOKazgidrometGaz.unittype'
            ],
            filters: [...filters, kazgidroFilter]
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) return null;
            const data = resultSet.rawData();
            if (!data.length) return null;
            // return <pre>{JSON.stringify(data, null, 2)}</pre>;
            return (
              <div>
                <div style={{ marginBottom: 10 }}>
                  {data.length
                    ? data[0]['SmartECOKazgidrometGaz.postAdditionalInformation']
                    : null}
                </div>
                <div className="air-quality-widget">
                  <Row gutter={20}>
                    <Col span={12}>
                      <h3>
                        {data[0]['DStation.pnzNumber']},{' '}
                        {data[0]['DStation.locality']},{' '}
                        {data[0]['DStation.address']} (
                      <span
                          style={{
                            color:
                              data[0]['DStation.type'] === 'автоматика'
                                ? '#32CD66'
                                : '#FF6600'
                          }}
                        >
                          {data[0]['DStation.type']}
                        </span>
                      )
                    </h3>

                      <p>Пост наблюдения загрязнения атмосферного воздуха</p>
                    </Col>
                    <Col span={12}>
                      {/* <img src="./images/house-with-cloud.png" width={40} /> */}
                    </Col>
                  </Row>

                  <div className="widget-blocks">
                    <Row className="air-q">
                      {data
                        .filter(d =>
                          CRITICAL.includes(
                            d['SmartECOKazgidrometGaz.measureNameRu']
                          )
                        )
                        .map(d => {
                          const value = Number(d['SmartECOKazgidrometGaz.value']);
                          const maxValue = Number(d['SmartECOKazgidrometGaz.maxpokazatel']);
                          const title = d['SmartECOKazgidrometGaz.measureNameRu'];
                          return (
                            <Col span={12}>
                              <div className="content-wrap">
                                <h4>{title}</h4>
                                <h2 style={{ color: `${this.returnColor(this.returnPercentage(value, maxValue))}` }}>{value.toFixed(3)} {d["SmartECOKazgidrometGaz.unittype"]}</h2>
                                <p style={{ color: `${this.returnColor(this.returnPercentage(value, maxValue))}` }}>
                                  {this.returnLevel(this.returnPercentage(value, maxValue))}
                                </p>
                              </div>
                              <div className="graph-vertival">
                                <ul style={{ marginBottom: 0 }}>
                                  <li className="red" />
                                  <li className="orange" />
                                  <li className="orange" />
                                  <span
                                    style={{
                                      bottom: `${this.returnPercentage(
                                        value.toFixed(3),
                                        maxValue.toFixed(3)
                                      )}%`, fontSize: "20px", color: `${this.returnColor(this.returnPercentage(value, maxValue))}`
                                    }}
                                  >
                                    &#8594;
                                      </span>
                                  <li className="yellow" />
                                  <li className="yellow" />
                                  <li className="green" />
                                </ul>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}
