/*
  Objects Array = [
    {
      ballon: {
        clusterCaption: "List of objects at the coordinates",
        balloonContentHeader,
        balloonContentBody,
        balloonContentFooter
      }
    }
  ]
  EVENTS 
  onClusterClick - returns array of objects at that cluster
  handleClick - return clicked object
*/

import React from "react";
import { YMaps, Map, ZoomControl, FullscreenControl } from "react-yandex-maps";
import Loading from "../common/LoadingCss";

class YandexHeatMapWrap extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      loading: true,
      center: [49.807754, 73.088504],
      objects: [],
    };
    this.clusterer = null;
    this.ymaps = null;
    this.map = null;
  }

  onApiAvailable = (ymaps) => {
    this.ymaps = ymaps;
  };

  setMapControlInstanceRef = (ref) => {
    this.map = ref;
    setTimeout(() => {
      if (!this.heatmap) {
        this.initHeatMap();
      }
      if (this.heatmap) {
        this.heatmap.setMap(this.map);
        // this.getData();
      }
      this.setState({ loading: false });
    }, 100);
  };

  calculateDateRange = () => {
    const { dateRange: [start, end] } = this.props;
    return Math.abs(start.diff(end, 'days')) + 1;
  }  

  getMidPointIntensity = () => {
    const days = this.calculateDateRange();
    if (days >= 0 && days <= 2) return 0.07;
    const intensityRanges = {
      2: 0.07,
      6: 0.009,
      14: 0.006,
      30: 0.005,
      90: 0.004,
      180: 0.003,
  };
  const maxDay = Object.keys(intensityRanges)
      .find(range => days <= range);
  return intensityRanges[maxDay] || 0.002;
  }

  initHeatMap = () => {
    var ymaps = this.ymaps;
    var map = this.map;
    const script = document.createElement("script");
    script.src =
      "https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js";
    // script.async = true;
    document.body.appendChild(script);
    setTimeout(() => {
      const { objects } = this.props;
      ymaps.modules.require(["Heatmap"], (Heatmap) => {
        let data1 = {
          type: "FeatureCollection",
          features: this.returnMapData(objects),
        };
        let heatmap = new Heatmap(data1, {
          radius: 20,
          intensityOfMidpoint: this.getMidPointIntensity(),
          gradient: {
            0.1: "rgba(128, 255, 0, 0.7)",
            0.2: "rgba(255, 255, 0, 0.8)",
            0.7: "rgba(234, 72, 58, 0.9)",
            1.0: "rgba(162, 36, 25, 1)",
          },
        });
        heatmap.setMap(map);
        this.heatmap = heatmap;
      });
      this.setState({ loading: false });
    }, 100);
  };

  returnFr(val, max) {
    return (100 * val) / max;
  }

  returnMapData(data) {
    const { xDataKey, yDataKey, sumKey } = this.props;
    const unique_coords = [];
    let arr = data
      .map((item, index) => {
        const coords = item[yDataKey] + " " + item[xDataKey];
        if (!unique_coords.includes(coords)) {
          unique_coords.push(coords);
          return {
            id: "id" + index,
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item[yDataKey], item[xDataKey]],
            },
            properties: {
              weight: sumKey ? parseFloat(item[sumKey]) : 1,
            },
          };
        } else return null;
      })
      .filter((e) => e);
    return arr;
  }

  onObjectClick = (obj) => {
    const { idDataKey } = this.props;
    this.props.handleClick(obj[idDataKey]);
    this.changeMapCenter(obj);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.map) {
      this.map.destroy();
    }
  }

  createGeoObjects = (objects) => {
    const { xDataKey, yDataKey } = this.props;
    let geoObjects = [];
    if (!objects[0]) return [];
    objects.forEach((el) => {
      let coords = [el[yDataKey], el[xDataKey]];
      if (coords) {
        let obj = new this.ymaps.Placemark(coords, el.ballon, {
          iconColor: el.color ? el.color : "#3caa3c",
          preset: "islands#circleIcon",
        });
        obj.dataObject = el;
        obj.events.add("click", () => {
          this.onObjectClick(el);
        });
        geoObjects.push(obj);
      }
    });
    return geoObjects;
  };

  onClustererClick = (ev) => {
    if (!ev.get("target").dataObject) {
      const objects = ev
        .get("target")
        .getGeoObjects()
        .map((el) => el.dataObject);
      if (this.props.onClusterClick) {
        this.props.onClusterClick(objects);
      }
    }
  };

  createCluster = () => {
    const clusterer = new this.ymaps.Clusterer({
      hasBalloon: false,
      clusterDisableClickZoom: true,
      clusterIconLayout: "default#pieChart",
      clusterIconPieChartRadius: 25,
      clusterIconPieChartCoreRadius: 10,
      clusterIconPieChartStrokeWidth: 3,
      groupByCoordinates: false,
    });
    clusterer.events.add("click", (e) => {
      this.onClustererClick(e);
    });
    let geoObjects = this.createGeoObjects(this.state.objects);
    if (geoObjects[0]) clusterer.add(geoObjects);
    this.clusterer = clusterer;
    if (this.map) this.map.geoObjects.add(clusterer);
  };

  componentDidUpdate() {
    if (
      this.state.objects.length !== this.props.objects.length &&
      this._isMounted
    ) {
      this.setState(
        {
          objects: this.props.objects,
        },
        () => {
          if (this.clusterer) this.refreshClusterer();
          if (!this.props.showDefaultCity)
            this.changeMapCenter(this.props.objects[0]);
        }
      );
    }
  }

  changeMapCenter = (el) => {
    const { xDataKey, yDataKey } = this.props;
    let coords = [el[yDataKey], el[xDataKey]];
    this.setState({ center: coords });
  };

  refreshClusterer = () => {
    this.clusterer.removeAll(); // Delete all geoObjects
    let geoObjects = this.createGeoObjects(this.state.objects);
    if (geoObjects[0]) {
      this.clusterer.add(geoObjects); // Add new GeoObjeccts
    }
  };

  render() {
    let { height, zoom = 13 } = this.props;
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <YMaps onApiAvaliable={this.onApiAvailable}>
          <Map
            state={{
              center: this.state.center,
              zoom: zoom,
              controls: [],
            }}
            width="100%"
            height={height ? height : "55vh"}
            instanceRef={this.setMapControlInstanceRef}
          >
            <ZoomControl
              options={{
                size: "small",
                zoomDuration: 1000,
              }}
            />
            <FullscreenControl />
          </Map>
        </YMaps>
      </React.Fragment>
    );
  }
}

export default YandexHeatMapWrap;
