import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';

import "moment/locale/ru";

import App from "./App";

import "./style.scss";

moment.locale("ru");

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={ruRU}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
