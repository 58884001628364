import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Skeleton, Row, Table, Spin } from "antd";

import HorizontalStackedBarChart from "../charts/HorizontalStackedBarChart";
import HorizontalBarChartSeries from "../charts/HorizontalBarChartSeries";
import YandexMapWrapDetail from "../charts/YandexMapWrapDetail";
import ExportExcel from "../components/ExportExcel";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";

import {
  SingleNumber,
  PieChart,
  HorizontalBarChart as HorizonBarChart,
} from "../components/visualizations";
import {
  getSingleValue,
  generatePieData,
  generateBarData,
} from "../utils/visualizations";

import cubejs from "../cube";
import { numberFormatter } from "../utils/helpers";

const cubejsApi = cubejs({ appId: 1 });

const colorsCapacity = {
  "SchoolTypes.surplus": "#2AA6FF",
  "SchoolTypes.norm": "#3BBC56",
  "SchoolTypes.deficit": "#E94D4D",
};

const colorsCapacity1 = {
  Профицит: "#2BA6FF",
  Норма: "#3BBC56",
  Дефицит: "#E94D4D",
};

const colorsPrCapacity = {
  "SchoolTypes.prCapacity": "#2AA6FF",
  "SchoolTypes.kontingent": "#CAE9FF",
};

const detailQuery = {
  measures: ["SchoolTypes.prCapacity", "SchoolTypes.kontingent"],
  dimensions: [
    "SchoolTypes.id",
    "SchoolTypes.district",
    "SchoolTypes.locality",
    "SchoolTypes.name",
    "SchoolTypes.address",
    "SchoolTypes.localityType",
    "SchoolTypes.fullness",
  ],
};

const renderDetail = (items) => (
  <Table dataSource={items} pagination={false} rowKey="id" size="small">
    <Table.Column
      title="Наименование района"
      dataIndex="SchoolTypes.district"
      width={100}
    />
    <Table.Column
      title="Наименование населенного пункта"
      dataIndex="SchoolTypes.locality"
    />
    <Table.Column title="Наименование школы" dataIndex="SchoolTypes.name" />
    <Table.Column
      title="Адрес школы (улица, дом)"
      dataIndex="SchoolTypes.address"
    />
    <Table.Column
      title="Тип школы: городская, сельская"
      dataIndex="SchoolTypes.localityType"
    />
    <Table.Column
      title="Проектная мощность (уч.мест)"
      dataIndex="SchoolTypes.prCapacity"
    />
    <Table.Column title="Контингент" dataIndex="SchoolTypes.kontingent" />
    <Table.Column title="Заполненность" dataIndex="SchoolTypes.fullness" />
  </Table>
);

const exportFields = [
  {
    title: "Наименование района",
    dataIndex: "SchoolTypes.district",
  },
  {
    title: "Наименование населенного пункта",
    dataIndex: "SchoolTypes.locality",
  },
  {
    title: "Наименование школы",
    dataIndex: "SchoolTypes.name",
  },
  {
    title: "Адрес школы (улица, дом)",
    dataIndex: "SchoolTypes.address",
  },
  {
    title: "Тип школы: городская, сельская",
    dataIndex: "SchoolTypes.localityType",
  },
  {
    title: "Проектная мощность (уч.мест)",
    dataIndex: "SchoolTypes.prCapacity",
  },
  {
    title: "Дефицит ученических мест",
    dataIndex: "SchoolTypes.deficit",
  },
  {
    title: "В аварийном состоянии (да, нет)",
    dataIndex: "SchoolTypes.emergencySt",
  },
  {
    title: "Контингент",
    dataIndex: "SchoolTypes.kontingent",
  },
  {
    title: "Количество смен",
    dataIndex: "SchoolTypes.shiftCount",
  },
  {
    title: "Число учащихся по сменам 1",
    dataIndex: "SchoolTypes.firstShift",
  },
  {
    title: "Число учащихся по сменам 2",
    dataIndex: "SchoolTypes.secondShift",
  },
  {
    title: "Число учащихся по сменам 3",
    dataIndex: "SchoolTypes.thirdShift",
  },
  {
    title: "Предшкола",
    dataIndex: "SchoolTypes.preSchool",
  },
  {
    title: "Проектная мощность пришкольного интерната",
    dataIndex: "SchoolTypes.preCapacity",
  },
  {
    title: "Контингент проживающих пришкольного интерната",
    dataIndex: "SchoolTypes.preKontingent",
  },
  {
    title: "Язык обучения",
    dataIndex: "SchoolTypes.language",
  },
  {
    title: "Год ввода здания школы",
    dataIndex: "SchoolTypes.theYear",
  },
  {
    title: "Тип здания школы (типовое, присп.)",
    dataIndex: "SchoolTypes.buildingType",
  },
  {
    title: "Тип строения",
    dataIndex: "SchoolTypes.materialType",
  },
  {
    title: "Вид отопления",
    dataIndex: "SchoolTypes.heatingType",
  },
  {
    title: "Тип школы",
    dataIndex: "SchoolTypes.schoolType",
  },
  {
    title: "ФИО руководителя",
    dataIndex: "SchoolTypes.directorFio",
  },
  {
    title: "Телефоны",
    dataIndex: "SchoolTypes.contacts",
  },
  {
    title: "E-mail",
    dataIndex: "SchoolTypes.email",
  },
];

class Schools extends Component {
  state = {
    filtersData: {
      "SchoolTypes.district": {
        key: "SchoolTypes.district",
        title: "Район",
        options: [],
        values: [],
      },
      "SchoolTypes.locality": {
        key: "SchoolTypes.locality",
        title: "Населенный пункт",
        options: [],
        values: [],
      },
      "SchoolTypes.localityType": {
        key: "SchoolTypes.localityType",
        title: "Местность",
        options: [],
        values: [],
      },
      "SchoolTypes.language": {
        key: "SchoolTypes.language",
        title: "Язык обучения",
        options: [],
        values: [],
      },
    },
    selectedDistrict: null,
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, selectedDistrict } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values,
          },
        ];
      }
    });

    let filtersWithDistrict = [];

    if (selectedDistrict && selectedDistrict.length) {
      filtersWithDistrict = [
        ...filtersWithDistrict,
        {
          member: "SchoolTypes.district",
          operator: "equals",
          values: [...selectedDistrict],
        },
      ];
    }
    return (
      <MainWrapper>
        <RowWrapper>
          <ChartCard title={"Распределение учащихся по школам"}>
            <QueryRenderer
              query={{
                measures: [
                  "SchoolTypes.count",
                  "SchoolTypes.prCapacity",
                  "SchoolTypes.kontingent",
                  "SchoolTypes.square",
                  "SchoolTypes.deficit",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      paddingTop: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <SingleNumber title="Кол-во школ">
                      {resultSet &&
                        +getSingleValue(resultSet, "SchoolTypes.count")}{" "}
                      ед.
                    </SingleNumber>
                    <SingleNumber title="Проектная мощность">
                      {resultSet &&
                        getSingleValue(
                          resultSet,
                          "SchoolTypes.prCapacity"
                        )}{" "}
                      мест
                    </SingleNumber>
                    <SingleNumber title="Фактическая наполненность">
                      {resultSet &&
                        getSingleValue(
                          resultSet,
                          "SchoolTypes.kontingent"
                        )}{" "}
                      чел.
                    </SingleNumber>
                    <SingleNumber title="Общая площадь">
                      {resultSet &&
                        numberFormatter(
                          getSingleValue(resultSet, "SchoolTypes.square")
                        )}
                    </SingleNumber>
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title={"Карта"}>
            <QueryRenderer
              query={{
                dimensions: [
                  "SchoolTypes.id",
                  "SchoolTypes.x",
                  "SchoolTypes.y",
                  "SchoolTypes.fullness",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const data = resultSet.rawData().map((d) => ({
                  ...d,
                  color: colorsCapacity1[d["SchoolTypes.fullness"]],
                }));
                return (
                  <YandexMapWrapDetail
                    cubejsApi={cubejsApi}
                    query={detailQuery}
                    renderDetail={renderDetail}
                    data={data}
                    showDetails
                    idDataKey="SchoolTypes.id"
                    xDataKey="SchoolTypes.x"
                    yDataKey="SchoolTypes.y"
                    type="SchoolTypes.fullness"
                    height="420px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Статус заполненности школ">
            <QueryRenderer
              query={{
                measures: ["SchoolTypes.count"],
                dimensions: ["SchoolTypes.fullness"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (resultSet) {
                  return (
                    <PieChart
                      loading={!resultSet}
                      id="SchoolTypesAvailabilityPie"
                      data={
                        resultSet
                          ? resultSet.loadResponse.data.map((d) => {
                              return {
                                ...d,
                                color:
                                  colorsCapacity1[d["SchoolTypes.fullness"]],
                              };
                            })
                          : []
                      }
                      dataKey="SchoolTypes.count"
                      nameKey="SchoolTypes.fullness"
                      cubejsApi={cubejsApi}
                      query={detailQuery}
                      renderDetail={renderDetail}
                    />
                  );
                }
                return <></>;
              }}
            />
          </ChartCard>
          <ChartCard title="Языки преподавания школ">
            <QueryRenderer
              query={{
                measures: ["SchoolTypes.count"],
                dimensions: ["SchoolTypes.language"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="SchoolTypesLanguagePie"
                    {...generatePieData(resultSet)}
                    cubejsApi={cubejsApi}
                    colorList={["#AC24FF", "#FFC224", "#FCFCFC"]}
                    query={detailQuery}
                    renderDetail={renderDetail}
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title={"Тип школ"}>
            <QueryRenderer
              query={{
                measures: ["SchoolTypes.count"],
                dimensions: ["SchoolTypes.schoolView"],
                filters,
                order: {
                  "SchoolTypes.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="SchoolTypesSchoolView"
                    {...generateBarData(resultSet)}
                    categoryText="Тип школы"
                    showValues
                    labelMaxWidth={400}
                    height="550px"
                    cubejsApi={cubejsApi}
                    query={detailQuery}
                    renderDetail={renderDetail}
                    colorsArr={["#2BA6FF"]}
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title={"Статус заполненности школ по регионам»:"}>
            <div style={{ marginLeft: 20 }}>
              <SelectList
                label={"Район:"}
                mode="multiple"
                onChange={(value) => this.setState({ selectedDistrict: value })}
                size="small"
                allowClear
                list={filtersData["SchoolTypes.district"].options}
              />
            </div>

            <QueryRenderer
              query={{
                measures: [
                  "SchoolTypes.surplus",
                  "SchoolTypes.norm",
                  "SchoolTypes.deficit",
                ],
                dimensions: ["SchoolTypes.district"],
                filters: filtersWithDistrict,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const data = resultSet.rawData();
                const series = resultSet.seriesNames().map((s) => ({
                  ...s,
                  color: colorsCapacity[s.key],
                }));
                return (
                  <HorizontalStackedBarChart
                    id="SchoolTypesAvailabilityByRegion"
                    data={data}
                    category="SchoolTypes.district"
                    values={series}
                    key={data.length}
                    cubejsApi={cubejsApi}
                    query={detailQuery}
                    renderDetail={renderDetail}
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Заполненность школ" style={{ width: "47%" }}>
            <QueryRenderer
              query={{
                measures: ["SchoolTypes.kontingent", "SchoolTypes.prCapacity"],
                dimensions: ["SchoolTypes.name"],
                filters: filtersWithDistrict,
                order: {
                  "SchoolTypes.prCapacity": "desc",
                },
                limit: 20,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Spin />;
                const data = resultSet.rawData();
                const series = resultSet.seriesNames().map((s) => ({
                  ...s,
                  color: colorsPrCapacity[s.key],
                }));
                return (
                  <HorizontalBarChartSeries
                    id="SchoolTypesBySchool"
                    data={data}
                    category="SchoolTypes.name"
                    values={series}
                    key={data.length}
                    cubejsApi={cubejsApi}
                    query={detailQuery}
                    renderDetail={renderDetail}
                    height="700px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title={"Таблица"} style={{ width: "47%" }}>
            <QueryRenderer
              query={{
                measures: [
                  "SchoolTypes.prCapacity",
                  "SchoolTypes.kontingent",
                  "SchoolTypes.deficit",
                ],
                dimensions: [
                  "SchoolTypes.id",
                  "SchoolTypes.shiftCount",
                  "SchoolTypes.firstShift",
                  "SchoolTypes.secondShift",
                  "SchoolTypes.thirdShift",
                  // "SchoolTypes.preSchool",
                  // "SchoolTypes.preCapacity",
                  // "SchoolTypes.preKontingent",
                  "SchoolTypes.district",
                  "SchoolTypes.locality",
                  "SchoolTypes.name",
                  "SchoolTypes.address",
                  "SchoolTypes.localityType",
                  // "SchoolTypes.emergencySt",
                  "SchoolTypes.language",
                  // "SchoolTypes.theYear",
                  "SchoolTypes.buildingType",
                  // "SchoolTypes.materialType",
                  // "SchoolTypes.heatingType",
                  "SchoolTypes.schoolType",
                  "SchoolTypes.directorFio",
                  // "SchoolTypes.contacts",
                  "SchoolTypes.email",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const data = resultSet.rawData();
                return (
                  <div style={{ padding: 10 }}>
                    <Row type="flex" justify="end" style={{ marginBottom: 10 }}>
                      <ExportExcel
                        filename="Школы"
                        data={data}
                        fields={exportFields}
                      />
                    </Row>
                    <Table
                      loading={!resultSet}
                      dataSource={data}
                      pagination={{ pageSize: 50 }}
                      rowKey="id"
                      size="small"
                      scroll={{ x: "max-content", y: 500 }}
                    >
                      <Table.Column
                        title="Наименование района"
                        dataIndex="SchoolTypes.district"
                        width={200}
                        textWrap="word-break"
                        sorter={(a, b) =>
                          a["SchoolTypes.district"].localeCompare(
                            b["SchoolTypes.district"]
                          )
                        }
                      />
                      <Table.Column
                        title="Наименование населенного пункта"
                        dataIndex="SchoolTypes.locality"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Наименование школы"
                        dataIndex="SchoolTypes.name"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Адрес школы (улица, дом)"
                        dataIndex="SchoolTypes.address"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Тип школы: городская, сельская"
                        dataIndex="SchoolTypes.localityType"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Проектная мощность (уч.мест)"
                        dataIndex="SchoolTypes.prCapacity"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Дефицит ученических мест"
                        dataIndex="SchoolTypes.deficit"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="В аварийном состоянии (да, нет)"
                        dataIndex="SchoolTypes.emergencySt"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Контингент"
                        dataIndex="SchoolTypes.kontingent"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Количество смен"
                        dataIndex="SchoolTypes.shiftCount"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Число учащихся по сменам 1"
                        dataIndex="SchoolTypes.firstShift"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Число учащихся по сменам 2"
                        dataIndex="SchoolTypes.secondShift"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Число учащихся по сменам 3"
                        dataIndex="SchoolTypes.thirdShift"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Предшкола"
                        dataIndex="SchoolTypes.preSchool"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Проектная мощность пришкольного интерната"
                        dataIndex="SchoolTypes.preCapacity"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Контингент проживающих пришкольного интерната"
                        dataIndex="SchoolTypes.preKontingent"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Язык обучения"
                        dataIndex="SchoolTypes.language"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Год ввода здания школы"
                        dataIndex="SchoolTypes.theYear"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Тип здания школы (типовое, присп.)"
                        dataIndex="SchoolTypes.buildingType"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Тип строения"
                        dataIndex="SchoolTypes.materialType"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Вид отопления"
                        dataIndex="SchoolTypes.heatingType"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Тип школы"
                        dataIndex="SchoolTypes.schoolType"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="ФИО руководителя"
                        dataIndex="SchoolTypes.directorFio"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="Телефоны"
                        dataIndex="SchoolTypes.contacts"
                        width={200}
                        textWrap="word-break"
                      />
                      <Table.Column
                        title="E-mail"
                        dataIndex="SchoolTypes.email"
                        width={200}
                        textWrap="word-break"
                      />
                    </Table>
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}

export default Schools;
